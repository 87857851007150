import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { FlagIcon } from 'react-flag-kit';

import withData from '../store/withData';
import { getRequest } from '../utils/api';

function ClubSelect(props) {
  const { dataContext, type } = props;

  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadClubsData() {
      setLoading(true);
      const data = await getRequest(`${type}/clubs`);
      dataContext.setClubSelectOptions(data || { items: [] });
      setLoading(false);
    }
    if (update) {
      setUpdate(false);
      loadClubsData();
    }
  }, [update, dataContext, type]);

  const options = dataContext.clubSelectOptions.items.map((item) => (
    <Select.Option key={item.id + item.slug + item.country} value={item.id} title={item.name}>
      {`${item.name} `}
      <FlagIcon code={item.country} width={21} height={14} />
    </Select.Option>
  ));

  function onChange(value) {
    dataContext.setClubSelectValue(value || -1);
  }

  return (
    <Select
      style={{ width: 250 }}
      placeholder="Select a club"
      showSearch
      allowClear
      optionFilterProp="children"
      filterOption={(input, option) => (
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )}
      onChange={onChange}
      onClear={onChange}
      value={dataContext.clubSelectValue}
      loading={loading}
      disabled={loading}
    >
      <Select.Option key="all_clubs" value={-1} title="All Clubs">All Clubs</Select.Option>
      {options}
    </Select>
  );
}

export default withData(ClubSelect);
