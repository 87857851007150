import React, { useState, useEffect } from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getRequest } from '../utils/api';
import withData from '../store/withData';
import RewardCard from '../components/RewardCard';
import Filters from '../components/Filters';
import { ViewTypes, generateParameters } from '../utils/filter';
import { useScreenDimensions } from '../utils/screen';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function AllCardsView(props) {
  const { dataContext } = props;

  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  const { width } = useScreenDimensions();
  const cardsFitInLine = Math.floor(width / 236);

  useEffect(() => {
    async function loadRewardsData() {
      let params = `market/all?offset=0&limit=${cardsFitInLine * 2}`;

      const filterParams = generateParameters(ViewTypes.ALL_CARDS, dataContext);

      if (filterParams.length) {
        params += `&${filterParams.join('&')}`;
      }

      setLoading(true);
      const data = await getRequest(params);
      dataContext.setRewards(data || { items: [] });
      setLoading(false);
    }
    if (update) {
      setUpdate(false);
      loadRewardsData();
    }
  }, [update, dataContext, cardsFitInLine]);

  const cards = dataContext.rewards.items.map((item) => (
    <RewardCard key={item.cardSlug} data={item} />
  ));

  async function onLoadMoreButtonClick() {
    const limit = cardsFitInLine * 2 - (dataContext.rewards.items.length % cardsFitInLine);
    let params = `market/all?offset=${dataContext.rewards.items.length}&limit=${limit}`;

    const filterParams = generateParameters(ViewTypes.ALL_CARDS, dataContext);

    if (filterParams.length) {
      params += `&${filterParams.join('&')}`;
    }

    setLoading(true);
    const data = await getRequest(params);
    if (data && data.items && data.items.length) {
      const newItems = dataContext.rewards.items.concat(data.items);
      dataContext.setRewards({ items: newItems });
    }
    setLoading(false);
  }

  document.title = 'SorareAce - All Cards';

  return (
    <div className="sa-auction-view">
      <Spin indicator={LoadingIcon} spinning={loading}>
        <Filters dataContext={dataContext} setUpdate={setUpdate} view={ViewTypes.ALL_CARDS} type="market/listed" />
        <div className="sa-cards">
          {cards.length ? cards : 'No cards found'}
        </div>
        <Button block onClick={onLoadMoreButtonClick}>Load more</Button>
      </Spin>
    </div>
  );
}

export default withData(AllCardsView);
