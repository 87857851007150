import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getRequest } from '../utils/api';
import withData from '../store/withData';
import AuctionCard from '../components/AuctionCard';
import Filters from '../components/Filters';
import { ViewTypes, generateParameters } from '../utils/filter';
import { useScreenDimensions } from '../utils/screen';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function AuctionsView(props) {
  const { dataContext } = props;

  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  const { width } = useScreenDimensions();
  const cardsFitInLine = Math.floor(width / 236);

  const prepareParams = useCallback((offset) => {
    const limit = cardsFitInLine * 2 - (offset % cardsFitInLine);
    let params = `?offset=${offset}&limit=${limit}`;

    const filterParams = generateParameters(ViewTypes.AUCTIONS, dataContext);

    if (filterParams.length) {
      params += `&${filterParams.join('&')}`;
    }

    return params;
  }, [dataContext, cardsFitInLine]);

  useEffect(() => {
    async function loadAuctionsData() {
      setLoading(true);
      const params = prepareParams(0);
      const data = await getRequest(`auctions${params}`);
      dataContext.setAuctions(data || { items: [] });
      setLoading(false);
    }
    if (update) {
      setUpdate(false);
      loadAuctionsData();
    }
  }, [update, dataContext, prepareParams]);

  const thisRef = useRef(null);

  const cards = dataContext.auctions.items.map((item, index) => (
    <AuctionCard
      key={item.cardSlug}
      data={item}
      reference={(index === dataContext.auctions.items.length - 1) ? thisRef : null}
    />
  ));

  function getNumberOfActiveAuctions(auctions) {
    return auctions.filter((item) => Date.now() < Date.parse(item.endDate)).length;
  }

  const onLoadMoreButtonClick = useCallback(async () => {
    setLoading(true);
    const params = prepareParams(getNumberOfActiveAuctions(dataContext.auctions.items));
    const data = await getRequest(`auctions${params}`);
    if (data && data.items && data.items.length) {
      const newItems = dataContext.auctions.items.concat(data.items);
      dataContext.setAuctions({ items: newItems });
    }
    setLoading(false);
  }, [dataContext, prepareParams]);

  useEffect(() => {
    if (!thisRef.current) {
      return null;
    }

    let observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onLoadMoreButtonClick();
          observer = observer.disconnect();
        }
      }),
    );
    observer.observe(thisRef.current);

    return () => { observer = observer && observer.disconnect(); return observer; };
  }, [thisRef, onLoadMoreButtonClick]);

  document.title = 'SorareAce - Active Auctions';

  return (
    <div className="sa-auction-view">
      <Spin indicator={LoadingIcon} spinning={loading}>
        <Filters dataContext={dataContext} setUpdate={setUpdate} view={ViewTypes.AUCTIONS} type="auctions" />
        <div className="sa-cards">
          {cards.length ? cards : 'No active auctions'}
        </div>
        <Button block onClick={onLoadMoreButtonClick}>Load more</Button>
      </Spin>
    </div>
  );
}

export default withData(AuctionsView);
