import React, { useState } from 'react';
import { Table } from 'antd';
import { FlagIcon } from 'react-flag-kit';

import PriceBlock from './PriceBlock';
import Scores from './Scores';

function PlayersList({
  data, selectedRarity, selectedScoresType, selectedScoresSort,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  let scoresTitle = '';
  switch (selectedScoresType) {
    case 'total':
      scoresTitle = 'All Scores';
      break;

    case 'club':
      scoresTitle = 'Club Scores';
      break;

    case 'national_team':
      scoresTitle = 'National Team Scores';
      break;

    default:
      scoresTitle = 'Unknown Scores';
      break;
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (v, r, index) => ((currentPage - 1) * 10 + index + 1),
    },
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (value, item) => (
        <a href={`/player/${item.slug}`} target="_blank" rel="noreferrer">
          <div className="sa-player-search-row">
            <span style={{ marginRight: '10px', fontSize: '18px', fontWeight: 'bold' }}>{value}</span>
            {item.countrySlug && (
              <FlagIcon code={item.countrySlug} width={24} height={16} />
            )}
          </div>
        </a>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Age (Birthday)',
      dataIndex: 'birthDate',
      key: 'birthDate',
      render: (birthDate, item) => (
        <div style={{ marginRight: '8px', fontSize: '14px' }}>
          <div>{`${item.age} years`}</div>
          <div>{`(${(new Date(birthDate)).toLocaleDateString()})`}</div>
        </div>
      ),
    },
    {
      title: 'Club',
      dataIndex: 'clubName',
      key: 'clubName',
      render: (clubName, item) => (
        <>
          <div className="sa-player-search-row">
            {item.clubPictureUrl && (
              <img src={item.clubPictureUrl} alt="club emblem" width="30" />
            )}
            {(clubName && item.competionCovered) ? (
              <div style={{ margin: '0 8px 0 8px', fontSize: '16px', fontWeight: 'bold' }}>
                {clubName}
              </div>
            ) : (
              <div
                style={{
                  margin: '0 8px 0 8px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: 'red',
                }}
              >
                {clubName || 'No club data'}
              </div>
            )}
            {item.clubCountrySlug && (
              <FlagIcon code={item.clubCountrySlug} width={24} height={16} />
            )}
          </div>
          {(item.leagueName && item.competionCovered) ? (
            <div style={{ fontSize: '16px' }}>
              {`(${item.leagueName})`}
            </div>
          ) : (
            <div style={{ fontSize: '16px', color: 'red' }}>
              {`(${item.leagueName ? item.leagueName : 'No league data'})`}
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Prices',
      dataIndex: 'prices',
      key: 'prices',
      render: (v, item) => (
        <PriceBlock data={item} type={selectedRarity} />
      ),
    },
    {
      title: scoresTitle,
      dataIndex: 'stats',
      key: 'scores',
      render: (stats) => (
        <div style={{ overflowWrap: 'normal', marginRight: '10px' }}>
          <Scores data={stats} highlightScore={selectedScoresSort} />
        </div>
      ),
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={{
        showSizeChanger: false,
        onChange: onChangePage,
      }}
    />
  );
}

export default PlayersList;
