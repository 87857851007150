import React from 'react';
import { Card, Tooltip } from 'antd';

import { TimeAndBadges } from './CardParts';

import { getFormattedNumber } from '../utils/number';

function Prices(props) {
  const { data } = props;

  return (
    <div className="sa-auction-prices-block">
      <Tooltip title="The last auction price">
        <div className="sa-auction-market-price">
          {data.auctionPrice.eth > 0 ? `Ξ${getFormattedNumber(data.auctionPrice.eth)}` : 'No data'}
        </div>
      </Tooltip>
      <Tooltip title="Listed price">
        <a
          href={`https://sorare.com/football/cards/${data.cardSlug}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <div className="sa-auction-price">
            {data.currentPrice.eth > 0 ? `Ξ${getFormattedNumber(data.currentPrice.eth)}` : 'No data'}
          </div>
        </a>
      </Tooltip>
      <Tooltip title="The lowest market price (floor)">
        <div className="sa-auction-market-price">
          {data.bestMarketPrice > 0 ? `Ξ${getFormattedNumber(data.bestMarketPrice)}` : 'No Data'}
        </div>
      </Tooltip>
    </div>
  );
}

function ListedCardNoScores(props) {
  const { data } = props;

  return (
    <div className="sa-auction-card-container">
      <Card
        cover={(
          <a href={`https://sorare.com/football/cards/${data.cardSlug}`} target="_blank" rel="noreferrer">
            <img
              key={data.cardSlug}
              src={data.pictureUrl}
              alt={data.playerName}
              width={216}
              height={350}
            />
          </a>
        )}
      >
        <div className="sa-card-bottom-no-scores">
          <TimeAndBadges data={data} />
          <Prices data={data} />
        </div>
      </Card>
    </div>
  );
}

export default ListedCardNoScores;
