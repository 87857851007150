import React from 'react';

import Score from './Score';

function Scores(props) {
  const {
    data, perGames = true, highlightScore, type,
  } = props;

  if (!data) {
    return null;
  }

  return (
    <>
      <Score
        stats={data.last5}
        perGames={perGames}
        highlight={highlightScore ? highlightScore === 'last5' : highlightScore}
        detailed={type !== 'normal'}
      />
      {data.last15.games > data.last5.games && (
      <Score
        stats={data.last15}
        perGames={perGames}
        highlight={highlightScore ? highlightScore === 'last15' : highlightScore}
        detailed={type !== 'normal'}
      />
      )}
      {data.last30.games > data.last15.games && (
      <Score
        stats={data.last30}
        perGames={perGames}
        highlight={highlightScore ? highlightScore === 'last30' : highlightScore}
        detailed={type !== 'normal'}
      />
      )}
      {data.all.games > data.last30.games && (
      <Score
        stats={data.all}
        perGames={perGames}
        highlight={highlightScore ? highlightScore === 'all' : highlightScore}
        detailed={type !== 'normal'}
      />
      )}
    </>
  );
}

export default Scores;
