import React, { useState } from 'react';
import {
  Select, Switch, Button, Space,
} from 'antd';

import ClubSelect from './ClubSelect';
import NbaClubSelect from './NbaClubSelect';
import LeagueSelect from './LeagueSelect';

import PlayedFilterModal from './PlayedFilterModal';
import PricesFilterModal from './PricesFilterModal';
import ScoresFilterModal from './ScoresFilterModal';

import { FilterTypes, isAvailable } from '../utils/filter';

function CardsSortOrderSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 200 }}
      placeholder="Select a cards sort order"
      onChange={onChange}
      value={value}
    >
      <Select.Option value="recentDesc">By Date of Addition (↓)</Select.Option>
      <Select.Option value="recentAsc">By Date of Addition (↑)</Select.Option>
      <Select.Option value="positionDesc">By Position (↓)</Select.Option>
      <Select.Option value="positionAsc">By Position (↑)</Select.Option>
    </Select>
  );
}

function ScoresTypeSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 220 }}
      onChange={onChange}
      value={value}
    >
      <Select.Option value="total">Use All Scores</Select.Option>
      <Select.Option value="club">Use Club Scores</Select.Option>
      <Select.Option value="national_team">Use National Team Scores</Select.Option>
    </Select>
  );
}

function ScoresSortSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 200 }}
      onChange={onChange}
      value={value}
    >
      <Select.Option value="last5">Sort by Last 5 Games</Select.Option>
      <Select.Option value="last15">Sort by Last 15 Games</Select.Option>
      <Select.Option value="last30">Sort by Last 30 Games</Select.Option>
      <Select.Option value="all">Sort by ALL Games</Select.Option>
    </Select>
  );
}

function NbaScoresSortSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 200 }}
      onChange={onChange}
      value={value}
    >
      <Select.Option value="gameWeek">Sort by GWs Average</Select.Option>
      <Select.Option value="diff">Sort by Difference</Select.Option>
      <Select.Option value="last10">Sort by Last 10 Games</Select.Option>
      <Select.Option value="last20">Sort by Last 20 Games</Select.Option>
      <Select.Option value="last40">Sort by Last 40 Games</Select.Option>
      <Select.Option value="all">Sort by ALL Games</Select.Option>
    </Select>
  );
}

function NbaLineupSortSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 200 }}
      onChange={onChange}
      value={value}
    >
      <Select.Option value="diff">Sort by Difference</Select.Option>
      <Select.Option value="last10">Sort by Last 10 Games</Select.Option>
      <Select.Option value="gameWeek">Sort by GWs Average</Select.Option>
    </Select>
  );
}

function RaritySelect({ value, onChange }) {
  return (
    <Select
      mode="multiple"
      style={{ width: 150 }}
      placeholder="Select a rarity to show"
      onChange={onChange}
      value={value}
    >
      <Select.Option value="all">All Rarities</Select.Option>
      <Select.Option value="limited">Limited</Select.Option>
      <Select.Option value="rare">Rares</Select.Option>
      <Select.Option value="super_rare">Super Rares</Select.Option>
      <Select.Option value="unique">Uniques</Select.Option>
      <Select.Option value="custom_series">Custom Series</Select.Option>
    </Select>
  );
}

function RarityPricesSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 150 }}
      placeholder="Select a rarity for prices"
      onChange={onChange}
      value={value}
    >
      <Select.Option value="limited">Limited Prices</Select.Option>
      <Select.Option value="rare">Rare Prices</Select.Option>
      <Select.Option value="super_rare">Super Rare Prices</Select.Option>
      <Select.Option value="unique">Unique Prices</Select.Option>
    </Select>
  );
}

function PositionSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 150 }}
      placeholder="Select a position to show"
      onChange={onChange}
      value={value}
    >
      <Select.Option value="all">All Positions</Select.Option>
      <Select.Option value="Goalkeeper">Goalkeeper</Select.Option>
      <Select.Option value="Defender">Defender</Select.Option>
      <Select.Option value="Midfielder">Midfielder</Select.Option>
      <Select.Option value="Forward">Forward</Select.Option>
    </Select>
  );
}

function U23Switch({ value, onChange }) {
  return (
    <Switch checkedChildren="Under 23" unCheckedChildren="Under 23" checked={value} onChange={onChange} />
  );
}

function LegendsSwitch({ value, onChange }) {
  return (
    <Switch checkedChildren="Legends" unCheckedChildren="Legends" checked={value} onChange={onChange} />
  );
}

function FirstNumberSwitch({ value, onChange }) {
  return (
    <Switch checkedChildren="First Number" unCheckedChildren="First Number" checked={value} onChange={onChange} />
  );
}

function ShirtNumberSwitch({ value, onChange }) {
  return (
    <Switch checkedChildren="Jersey Number" unCheckedChildren="Jersey Number" checked={value} onChange={onChange} />
  );
}

function SpecialDesignSwitch({ value, onChange }) {
  return (
    <Switch checkedChildren="Special Design" unCheckedChildren="Special Design" checked={value} onChange={onChange} />
  );
}

function RewardSwitch({ value, onChange }) {
  return (
    <Switch checkedChildren="Reward" unCheckedChildren="Reward" checked={value} onChange={onChange} />
  );
}

function Low5thScoreSwitch({ value, onChange }) {
  return (
    <Switch checkedChildren="Low 5th Score" unCheckedChildren="Low 5th Score" checked={value} onChange={onChange} />
  );
}

function PlayedLastMatchSwitch({ value, onChange }) {
  return (
    <Switch checkedChildren="Last Match" unCheckedChildren="Last Match" checked={value} onChange={onChange} />
  );
}

function Filters(props) {
  const {
    dataContext, setUpdate, view, type,
  } = props;

  const [isPlayedModalVisible, setPlayedModalVisible] = useState(false);
  const [isPricesModalVisible, setPricesModalVisible] = useState(false);
  const [isScoresModalVisible, setScoresModalVisible] = useState(false);

  function onPlayedFilterButtonClick() {
    setPlayedModalVisible(true);
  }

  function onPricesFilterButtonClick() {
    setPricesModalVisible(true);
  }

  function onScoresFilterButtonClick() {
    setScoresModalVisible(true);
  }

  function onResetFiltersButtonClick() {
    dataContext.resetFilters();
    setUpdate(true);
  }

  function onApplyFiltersButtonClick() {
    setUpdate(true);
  }

  function onChangeCardsSortOrderSelect(value) {
    dataContext.setCardsSortOrderSelectValue(value);
  }

  function onChangeScoresTypeSelect(value) {
    dataContext.setScoresTypeSelectValue(value);
  }

  function onChangeScoresSortSelect(value) {
    dataContext.setScoresSortSelectValue(value);
  }

  function onChangeNbaScoresSortSelect(value) {
    dataContext.setNbaScoresSortSelectValue(value);
  }

  function onChangeNbaLineupScoresSortSelect(value) {
    dataContext.setNbaLineupScoresSortSelectValue(value);
  }

  function onChangeRaritySelect(value) {
    dataContext.setRaritySelectValue(value);
  }

  function onChangeRarityPricesSelect(value) {
    dataContext.setRarityPricesSelectValue(value);
  }

  function onChangePositionSelect(value) {
    dataContext.setPositionSelectValue(value);
  }

  function onChangeU23Switch(value) {
    dataContext.setU23SwitchValue(value);
  }

  function onChangeLegendsSwitch(value) {
    dataContext.setLegendsSwitchValue(value);
  }

  function onChangeFirstNumberSwitch(value) {
    dataContext.setFirstNumberSwitchValue(value);
  }

  function onChangeShirtNumberSwitch(value) {
    dataContext.setShirtNumberSwitchValue(value);
  }

  function onChangeSpecialDesignSwitch(value) {
    dataContext.setSpecialDesignSwitchValue(value);
  }

  function onChangeRewardSwitch(value) {
    dataContext.setRewardSwitchValue(value);
  }

  function onChangeLow5thScoreSwitch(value) {
    dataContext.setLow5thScoreSwitchValue(value);
  }

  function onChangePlayedLastMatchSwitch(value) {
    dataContext.setPlayedLastMatchSwitchValue(value);
  }

  return (
    <div className="sa-auction-filters">
      <div>
        <Space>
          {isAvailable(view, FilterTypes.CARDS_SORT_ORDER_BY) && (
            <CardsSortOrderSelect
              value={dataContext.cardsSortOrderSelectValue}
              onChange={onChangeCardsSortOrderSelect}
            />
          )}
          {isAvailable(view, FilterTypes.SCORES_TYPE) && (
            <ScoresTypeSelect
              value={dataContext.scoresTypeSelectValue}
              onChange={onChangeScoresTypeSelect}
            />
          )}
          {isAvailable(view, FilterTypes.SCORES_SORT_BY) && (
            <ScoresSortSelect
              value={dataContext.scoresSortSelectValue}
              onChange={onChangeScoresSortSelect}
            />
          )}
          {isAvailable(view, FilterTypes.NBA_SCORES_SORT_BY) && (
            <NbaScoresSortSelect
              value={dataContext.nbaScoresSortSelectValue}
              onChange={onChangeNbaScoresSortSelect}
            />
          )}
          {isAvailable(view, FilterTypes.NBA_LINEUP_SORT_BY) && (
            <NbaLineupSortSelect
              value={dataContext.nbaLineupScoresSortSelectValue}
              onChange={onChangeNbaLineupScoresSortSelect}
            />
          )}
          {isAvailable(view, FilterTypes.RARITY) && (
            <RaritySelect
              value={dataContext.raritySelectValue}
              onChange={onChangeRaritySelect}
            />
          )}
          {isAvailable(view, FilterTypes.POSITION) && (
            <PositionSelect
              value={dataContext.positionSelectValue}
              onChange={onChangePositionSelect}
            />
          )}
          {isAvailable(view, FilterTypes.LEAGUE) && (
            <LeagueSelect type={type} />
          )}
          {isAvailable(view, FilterTypes.CLUB) && (
            <ClubSelect type={type} />
          )}
          {isAvailable(view, FilterTypes.NBA_CLUB) && (
            <NbaClubSelect />
          )}
        </Space>
      </div>
      <Space>
        {isAvailable(view, FilterTypes.U23) && (
          <U23Switch
            value={dataContext.u23SwitchValue}
            onChange={onChangeU23Switch}
          />
        )}
        {isAvailable(view, FilterTypes.LEGENDS) && (
          <LegendsSwitch
            value={dataContext.legendsSwitchValue}
            onChange={onChangeLegendsSwitch}
          />
        )}
        {isAvailable(view, FilterTypes.FIRST) && (
          <FirstNumberSwitch
            value={dataContext.firstNumberSwitchValue}
            onChange={onChangeFirstNumberSwitch}
          />
        )}
        {isAvailable(view, FilterTypes.SHIRT) && (
          <ShirtNumberSwitch
            value={dataContext.shirtNumberSwitchValue}
            onChange={onChangeShirtNumberSwitch}
          />
        )}
        {isAvailable(view, FilterTypes.SPECIAL_DESIGN) && (
          <SpecialDesignSwitch
            value={dataContext.specialDesignSwitchValue}
            onChange={onChangeSpecialDesignSwitch}
          />
        )}
        {isAvailable(view, FilterTypes.REWARD) && (
          <RewardSwitch
            value={dataContext.rewardSwitchValue}
            onChange={onChangeRewardSwitch}
          />
        )}
        {isAvailable(view, FilterTypes.LOW_5TH_SCORE) && (
          <Low5thScoreSwitch
            value={dataContext.low5thScoreSwitchValue}
            onChange={onChangeLow5thScoreSwitch}
          />
        )}
        {isAvailable(view, FilterTypes.PLAYED_LAST_MATCH) && (
          <PlayedLastMatchSwitch
            value={dataContext.playedLastMatchSwitchValue}
            onChange={onChangePlayedLastMatchSwitch}
          />
        )}
        {isAvailable(view, FilterTypes.PLAYED_PERCENTAGES) && (
          <Button onClick={onPlayedFilterButtonClick}>Played Games Filter...</Button>
        )}
        {isAvailable(view, FilterTypes.RARITY_PRICES) && (
          <RarityPricesSelect
            value={dataContext.rarityPricesSelectValue}
            onChange={onChangeRarityPricesSelect}
          />
        )}
        {isAvailable(view, FilterTypes.PRICES) && (
          <Button onClick={onPricesFilterButtonClick}>Prices Filter...</Button>
        )}
        {isAvailable(view, FilterTypes.SCORES) && (
          <Button onClick={onScoresFilterButtonClick}>Scores Filter...</Button>
        )}
        <Button type="primary" onClick={onResetFiltersButtonClick}>Reset all</Button>
        <Button type="primary" onClick={onApplyFiltersButtonClick}>Apply</Button>
      </Space>
      {isAvailable(view, FilterTypes.PLAYED_PERCENTAGES) && (
        <PlayedFilterModal
          isVisible={isPlayedModalVisible}
          setVisible={setPlayedModalVisible}
          dataContext={dataContext}
        />
      )}
      {isAvailable(view, FilterTypes.PRICES) && (
        <PricesFilterModal
          isVisible={isPricesModalVisible}
          setVisible={setPricesModalVisible}
          dataContext={dataContext}
        />
      )}
      {isAvailable(view, FilterTypes.SCORES) && (
        <ScoresFilterModal
          isVisible={isScoresModalVisible}
          setVisible={setScoresModalVisible}
          dataContext={dataContext}
        />
      )}
    </div>
  );
}

export default Filters;
