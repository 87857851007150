const getFormattedNumber = (number) => {
  if (number >= 1) {
    return number.toFixed(3);
  }

  return number.toFixed(4);
};

// eslint-disable-next-line import/prefer-default-export
export { getFormattedNumber };
