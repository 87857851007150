import React, { useState } from 'react';
import { Table, Tag } from 'antd';
import { FlagIcon } from 'react-flag-kit';

import PriceBlock from './PriceBlock';
import NbaScores from './NbaScores';
import GameWeekScores from './GameWeekScores';

import { getColorForScore } from '../utils/score';

function NbaPlayersList({
  data, selectedRarity, selectedScoresSort,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (v, r, index) => ((currentPage - 1) * 10 + index + 1),
    },
    {
      title: 'Player Info',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (value, item) => (
        <>
          <a href={`/nba/player/${item.slug}`} target="_blank" rel="noreferrer">
            <div className="sa-player-search-row">
              <span style={{ marginRight: '10px', fontSize: '18px', fontWeight: 'bold' }}>{value}</span>
              {item.countrySlug && (
              <FlagIcon code={item.countrySlug} width={24} height={16} />
              )}
            </div>
          </a>
          <div style={{ marginRight: '8px', fontSize: '14px' }}>
            <div>{`${item.age} years`}</div>
            <div>{`(${(new Date(item.birthDate)).toLocaleDateString()})`}</div>
          </div>
        </>
      ),
    },
    {
      title: 'Club',
      dataIndex: 'clubName',
      key: 'clubName',
      render: (clubName, item) => (
        <div className="sa-player-search-row">
          {item.clubPictureUrl && (
            <img src={item.clubPictureUrl} alt="club emblem" width="30" />
          )}
          <span style={{ marginLeft: '8px', fontSize: '16px', fontWeight: 'bold' }}>
            {clubName || 'No club data'}
          </span>
        </div>
      ),
    },
    {
      title: 'Prices',
      dataIndex: 'prices',
      key: 'prices',
      render: (v, item) => (
        <PriceBlock data={item} type={selectedRarity} game="nba" />
      ),
    },
    {
      title: 'Game Weeks Scores',
      dataIndex: 'stats',
      key: 'gameWeek',
      render: (stats) => (
        <div style={{ overflowWrap: 'normal', marginRight: '10px' }}>
          <GameWeekScores data={stats.gameWeek} highlightScore={selectedScoresSort} />
        </div>
      ),
      width: 300,
    },
    {
      title: 'Games Scores',
      dataIndex: 'stats',
      key: 'scores',
      render: (stats) => (
        <div style={{ overflowWrap: 'normal', marginRight: '10px' }}>
          <NbaScores data={stats} highlightScore={selectedScoresSort} />
        </div>
      ),
      width: 200,
    },
    {
      title: 'Sorare L10 Average',
      dataIndex: 'stats',
      key: 'sorareL10Average',
      render: (stats) => (
        <Tag
          key={Math.random() * 1000000}
          color={getColorForScore(stats?.sorareL10Average)}
          style={{ fontSize: '14px' }}
        >
          {stats?.sorareL10Average ? stats?.sorareL10Average.toFixed(1) : 'N/A'}
        </Tag>
      ),
    },
    {
      title: 'Difference from L10 Average',
      dataIndex: 'stats',
      key: 'diff',
      render: (stats) => {
        const gameWeekAverage = stats?.gameWeek?.averageScore || 0;
        const l10Average = stats?.isL10AverageCorrect === false
          ? stats?.sorareL10Average || 0 : stats?.last10?.averageScore || 0;
        const diff = gameWeekAverage - l10Average;
        return (
          <div className="sa-nba-diff-row">
            <Tag
              key={Math.random() * 1000000}
              color={getColorForScore(gameWeekAverage)}
              style={{ fontSize: '14px' }}
            >
              {gameWeekAverage.toFixed(1)}
            </Tag>
            <span style={{ margin: '0px 8px 0px 0px' }}>-</span>
            <Tag
              key={Math.random() * 1000000}
              color={getColorForScore(l10Average)}
              style={{ fontSize: '14px' }}
            >
              {l10Average.toFixed(1)}
            </Tag>
            <span style={{ margin: '0px 8px 0px 0px' }}>=</span>
            <Tag
              key={Math.random() * 1000000}
              color={diff > 0 ? 'green' : 'red'}
              style={{ fontSize: '16px' }}
            >
              {diff.toFixed(1)}
            </Tag>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={{
        showSizeChanger: false,
        onChange: onChangePage,
      }}
    />
  );
}

export default NbaPlayersList;
