import React, { useState, useEffect } from 'react';
import {
  Modal, Slider, Card, Button, Space,
} from 'antd';

import { scoreMarks } from '../utils/score';

function ScoreSlider({
  name, minValue, setMinValue, maxValue, setMaxValue,
}) {
  function onChange(value) {
    if (minValue !== value[0]) {
      setMinValue(value[0]);
    }

    if (maxValue !== value[1]) {
      setMaxValue(value[1]);
    }
  }

  function onLessThan50ButtonClick() {
    setMinValue(0);
    setMaxValue(50);
  }

  function onMoreThan50ButtonClick() {
    setMinValue(50);
    setMaxValue(100);
  }

  function onMoreThan60ButtonClick() {
    setMinValue(60);
    setMaxValue(100);
  }

  function onResetButtonClick() {
    setMinValue(0);
    setMaxValue(100);
  }

  const titleContent = (
    <div className="sa-scores-modal-score-title">
      <h4 style={{ marginBottom: 0 }}>{name}</h4>
      <Space>
        <Button type="default" onClick={onLessThan50ButtonClick}>0-50</Button>
        <Button type="default" onClick={onMoreThan50ButtonClick}>50+</Button>
        <Button type="default" onClick={onMoreThan60ButtonClick}>60+</Button>
        <Button type="default" danger onClick={onResetButtonClick}>Reset</Button>
      </Space>
    </div>
  );

  return (
    <>
      <Card title={titleContent} bordered>
        <Slider
          range
          step={5}
          min={0}
          max={100}
          marks={scoreMarks}
          value={[minValue, maxValue]}
          onChange={onChange}
        />
      </Card>
    </>
  );
}

function ScoresFilterModal(props) {
  const {
    dataContext, isVisible, setVisible,
  } = props;

  const { scoresFilter } = dataContext;

  const [minL15, setMinL15] = useState(scoresFilter.l15.min);
  const [maxL15, setMaxL15] = useState(scoresFilter.l15.max);
  const [minL30, setMinL30] = useState(scoresFilter.l30.min);
  const [maxL30, setMaxL30] = useState(scoresFilter.l30.max);
  const [minAll, setMinAll] = useState(scoresFilter.all.min);
  const [maxAll, setMaxAll] = useState(scoresFilter.all.max);

  useEffect(() => {
    if (isVisible) {
      setMinL15(scoresFilter.l15.min);
      setMaxL15(scoresFilter.l15.max);
      setMinL30(scoresFilter.l30.min);
      setMaxL30(scoresFilter.l30.max);
      setMinAll(scoresFilter.all.min);
      setMaxAll(scoresFilter.all.max);
    }
  }, // eslint-disable-next-line
  [isVisible]);

  function onOkClick() {
    setVisible(false);

    dataContext.setScoresFilter({
      l15: {
        min: minL15,
        max: maxL15,
      },
      l30: {
        min: minL30,
        max: maxL30,
      },
      all: {
        min: minAll,
        max: maxAll,
      },
    });
  }

  function onCancelClick() {
    setVisible(false);
  }

  return (
    <Modal
      title="Scores Filter"
      visible={isVisible}
      onOk={onOkClick}
      onCancel={onCancelClick}
      destroyOnClose
    >
      <ScoreSlider
        name="Last 15 scores"
        minValue={minL15}
        setMinValue={setMinL15}
        maxValue={maxL15}
        setMaxValue={setMaxL15}
      />
      <ScoreSlider
        name="Last 30 scores"
        minValue={minL30}
        setMinValue={setMinL30}
        maxValue={maxL30}
        setMaxValue={setMaxL30}
      />
      <ScoreSlider
        name="All scores"
        minValue={minAll}
        setMinValue={setMinAll}
        maxValue={maxAll}
        setMaxValue={setMaxAll}
      />
    </Modal>
  );
}

export default ScoresFilterModal;
