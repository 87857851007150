import React from 'react';
import { Tooltip } from 'antd';
import Icon, { SkinOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import { U23, Reward, Referral } from './CustomSvgIcons';
import { getTimeLeftFormatted } from '../utils/dateTime';

const U23_START_DATE = new Date('2000-07-01');

function U23Badge({ data }) {
  const playerBirthDate = new Date(data);

  if (playerBirthDate.getTime() < U23_START_DATE.getTime()) {
    return null;
  }

  return (
    <Tooltip title="U23 eligible">
      <div className="sa-u23-badge">
        <Icon component={U23} />
      </div>
    </Tooltip>
  );
}

function FirstNumberBadge({ data }) {
  if (data !== 1) {
    return null;
  }

  return (
    <Tooltip title="1st number">
      <div className="sa-1st-badge">
        ⓵
      </div>
    </Tooltip>
  );
}

function ShirtNumberBadge({ data }) {
  if (data.cardNumber !== data.cardShirtNumber) {
    return null;
  }

  return (
    <Tooltip title="Jersey number">
      <div className="sa-shirt-badge">
        <SkinOutlined />
      </div>
    </Tooltip>
  );
}

function RewardBadge({ data }) {
  if (data.lastTransferType !== 'reward') {
    return null;
  }

  return (
    <Tooltip title="Reward card">
      <div className="sa-shirt-badge">
        <Icon component={Reward} />
      </div>
    </Tooltip>
  );
}

function ReferralBadge({ data }) {
  if (data.lastTransferType !== 'referral') {
    return null;
  }

  return (
    <Tooltip title="Referral card">
      <div className="sa-shirt-badge">
        <Icon component={Referral} />
      </div>
    </Tooltip>
  );
}

function OnSaleBadge({ data, show }) {
  if (!show || !data.endDate) {
    return null;
  }

  return (
    <Tooltip title={`On sale - time left: ${getTimeLeftFormatted(data.endDate)}`}>
      <div className="sa-on-sale-badge">
        <Icon component={ShoppingCartOutlined} />
      </div>
    </Tooltip>
  );
}

export {
  U23Badge,
  ShirtNumberBadge,
  FirstNumberBadge,
  ReferralBadge,
  RewardBadge,
  OnSaleBadge,
};
