import React from 'react';

import { Tooltip, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

function CardRefresh(props) {
  const { data, show, onRefresh } = props;

  if (!show || !data) {
    return null;
  }

  return (
    <Tooltip title="Request to update card in DB">
      <div className="sa-refresh-button">
        <Button
          shape="circle"
          icon={<ReloadOutlined />}
          size="small"
          onClick={() => onRefresh(data)}
        />
      </div>
    </Tooltip>
  );
}

export default CardRefresh;
