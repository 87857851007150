import React, { useState, useEffect } from 'react';
import {
  Modal, Slider, Card, Button, Space, InputNumber,
} from 'antd';

const priceMarks = {
  0: {
    style: {
      fontWeight: 700,
    },
    label: '0',
  },
  0.05: {
    label: ' ',
  },
  0.1: {
    label: ' ',
  },
  0.2: {
    style: {
      fontWeight: 700,
    },
    label: '0.2',
  },
  0.3: {
    label: ' ',
  },
  0.4: {
    style: {
      fontWeight: 700,
    },
    label: '0.4',
  },
  0.5: {
    label: ' ',
  },
  0.6: {
    style: {
      fontWeight: 700,
    },
    label: '0.6',
  },
  0.7: {
    label: ' ',
  },
  0.8: {
    style: {
      fontWeight: 700,
    },
    label: '0.8',
  },
  0.9: {
    label: ' ',
  },
  1.0: {
    style: {
      fontWeight: 700,
    },
    label: '1.0',
  },
  1.25: {
    style: {
      fontWeight: 700,
    },
    label: '1.25',
  },
  1.5: {
    style: {
      fontWeight: 700,
    },
    label: '1.5',
  },
  1.75: {
    style: {
      fontWeight: 700,
    },
    label: '1.75',
  },
  2.0: {
    style: {
      fontWeight: 700,
    },
    label: '2.0+',
  },
};

function PriceSlider({
  name, minValue, setMinValue, maxValue, setMaxValue,
}) {
  function onChange(value) {
    if (minValue !== value[0]) {
      setMinValue(value[0]);
    }

    if (maxValue !== value[1]) {
      setMaxValue(value[1]);
    }
  }

  function onChangeMinValue(value) {
    if (minValue !== value) {
      setMinValue(value);
    }
  }

  function onChangeMaxValue(value) {
    if (maxValue !== value) {
      setMaxValue(value);
    }
  }

  function onLessThan01ButtonClick() {
    setMinValue(0);
    setMaxValue(0.1);
  }

  function onLessThan05ButtonClick() {
    setMinValue(0);
    setMaxValue(0.5);
  }

  function onResetButtonClick() {
    setMinValue(0);
    setMaxValue(2);
  }

  const titleContent = (
    <div className="sa-scores-modal-score-title">
      <h4 style={{ marginBottom: 0 }}>{name}</h4>
      <Space>
        <Button type="default" onClick={onLessThan01ButtonClick}>0-0.1</Button>
        <Button type="default" onClick={onLessThan05ButtonClick}>0-0.5</Button>
        <Button type="default" danger onClick={onResetButtonClick}>Reset</Button>
      </Space>
    </div>
  );

  return (
    <>
      <Card title={titleContent} bordered>
        <div className="sa-prices-modal-prices-inputs">
          <InputNumber
            addonBefore="Ξ"
            step={minValue < 1 ? 0.05 : 0.25}
            min={0}
            max={maxValue}
            value={minValue}
            onChange={onChangeMinValue}
            style={{
              width: 150,
            }}
          />
          <InputNumber
            addonBefore="Ξ"
            step={maxValue < 1 ? 0.05 : 0.25}
            min={minValue}
            max={10000}
            value={maxValue}
            onChange={onChangeMaxValue}
            style={{
              width: 150,
            }}
          />
        </div>
        <Slider
          range
          step={0.05}
          min={0}
          max={2}
          marks={priceMarks}
          value={[minValue, maxValue]}
          onChange={onChange}
        />
      </Card>
    </>
  );
}

function PricesFilterModal(props) {
  const {
    dataContext, isVisible, setVisible,
  } = props;

  const { pricesFilter } = dataContext;

  const [minLastAuction, setMinLastAuction] = useState(pricesFilter.lastAuction.min);
  const [maxLastAuction, setMaxLastAuction] = useState(pricesFilter.lastAuction.max);
  const [minCheapestMarket, setMinCheapestMarket] = useState(pricesFilter.cheapestMarket.min);
  const [maxCheapestMarket, setMaxCheapestMarket] = useState(pricesFilter.cheapestMarket.max);

  useEffect(() => {
    if (isVisible) {
      setMinLastAuction(pricesFilter.lastAuction.min);
      setMaxLastAuction(pricesFilter.lastAuction.max);
      setMinCheapestMarket(pricesFilter.cheapestMarket.min);
      setMaxCheapestMarket(pricesFilter.cheapestMarket.max);
    }
  }, // eslint-disable-next-line
  [isVisible]);

  function onOkClick() {
    setVisible(false);

    dataContext.setPricesFilter({
      lastAuction: {
        min: minLastAuction,
        max: maxLastAuction,
      },
      cheapestMarket: {
        min: minCheapestMarket,
        max: maxCheapestMarket,
      },
    });
  }

  function onCancelClick() {
    setVisible(false);
  }

  return (
    <Modal
      title="Prices Filter"
      visible={isVisible}
      onOk={onOkClick}
      onCancel={onCancelClick}
      destroyOnClose
    >
      <PriceSlider
        name="Last auction"
        minValue={minLastAuction}
        setMinValue={setMinLastAuction}
        maxValue={maxLastAuction}
        setMaxValue={setMaxLastAuction}
      />
      <PriceSlider
        name="Cheapest listed"
        minValue={minCheapestMarket}
        setMinValue={setMinCheapestMarket}
        maxValue={maxCheapestMarket}
        setMaxValue={setMaxCheapestMarket}
      />
    </Modal>
  );
}

export default PricesFilterModal;
