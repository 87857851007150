import React, { useState, useEffect } from 'react';
import {
  Modal, Slider, Card, Button, Space,
} from 'antd';

const marks = {
  0: {
    style: {
      fontWeight: 700,
    },
    label: '0',
  },
  25: {
    style: {
      fontWeight: 700,
    },
    label: '25',
  },
  50: {
    style: {
      fontWeight: 700,
    },
    label: '50',
  },
  75: {
    style: {
      fontWeight: 700,
    },
    label: '75',
  },
  100: {
    style: {
      fontWeight: 700,
    },
    label: '100',
  },
};

function PercentageSlider({
  name, value, setValue,
}) {
  function onChange(newValue) {
    if (value !== newValue) {
      setValue(newValue);
    }
  }

  function onMoreThan50ButtonClick() {
    setValue(50);
  }

  function on100ButtonClick() {
    setValue(100);
  }

  function onResetButtonClick() {
    setValue(0);
  }

  const titleContent = (
    <div className="sa-scores-modal-score-title">
      <h4 style={{ marginBottom: 0 }}>{name}</h4>
      <Space>
        <Button type="default" onClick={onMoreThan50ButtonClick}>50+</Button>
        <Button type="default" onClick={on100ButtonClick}>100</Button>
        <Button type="default" danger onClick={onResetButtonClick}>Reset</Button>
      </Space>
    </div>
  );

  return (
    <>
      <Card title={titleContent} bordered>
        <Slider
          step={5}
          min={0}
          max={100}
          marks={marks}
          value={value}
          onChange={onChange}
        />
      </Card>
    </>
  );
}

function PlayedFilterModal(props) {
  const {
    dataContext, isVisible, setVisible,
  } = props;

  const { playedFilter } = dataContext;

  const [l15, setL15] = useState(playedFilter.l15);
  const [l30, setL30] = useState(playedFilter.l30);
  const [all, setAll] = useState(playedFilter.all);

  useEffect(() => {
    if (isVisible) {
      setL15(playedFilter.l15);
      setL30(playedFilter.l30);
      setAll(playedFilter.all);
    }
  }, // eslint-disable-next-line
  [isVisible]);

  function onOkClick() {
    setVisible(false);

    dataContext.setPlayedFilter({ l15, l30, all });
  }

  function onCancelClick() {
    setVisible(false);
  }

  return (
    <Modal
      title="Played Games Percentages Filter"
      visible={isVisible}
      onOk={onOkClick}
      onCancel={onCancelClick}
      destroyOnClose
    >
      <PercentageSlider
        name="Last 15 games played, %"
        value={l15}
        setValue={setL15}
      />
      <PercentageSlider
        name="Last 30 games played, %"
        value={l30}
        setValue={setL30}
      />
      <PercentageSlider
        name="All games played, %"
        value={all}
        setValue={setAll}
      />
    </Modal>
  );
}

export default PlayedFilterModal;
