import * as React from 'react';
import DataContext from './DataContext';

function withData(Component) {
  return function DataComponent(props) {
    return (
      <DataContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />}
      </DataContext.Consumer>
    );
  };
}

export default withData;
