import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { ClockCircleTwoTone } from '@ant-design/icons';

import { getTimeLeftFormatted, getTimeLeftColor } from '../utils/dateTime';

function TimeLeft(props) {
  const { data, showEndDateTime, hide } = props;

  const [time, setTime] = useState(getTimeLeftFormatted(data, showEndDateTime));

  useEffect(() => {
    if (!data) {
      return null;
    }

    const interval = setInterval(() => setTime(getTimeLeftFormatted(data, showEndDateTime)), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [data, showEndDateTime]);

  if (hide || !data) {
    return null;
  }

  return (
    <Tooltip title="Time left">
      <ClockCircleTwoTone twoToneColor={getTimeLeftColor(data)} />
      {' '}
      {time}
    </Tooltip>
  );
}

export default TimeLeft;
