import React, { useState } from 'react';
import { Link } from '@reach/router';
import { Menu as AntMenu } from 'antd';
import {
  BarChartOutlined,
  ClockCircleOutlined,
  ShoppingCartOutlined,
  AppstoreOutlined,
  OrderedListOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';

import SearchModal from './SearchModal';
import RefreshCardsModal from './RefreshCardsModal';

import withData from '../store/withData';

const Menu = (props) => {
  const { location } = props;

  const [isSearchModalVisible, setSearchModalVisible] = useState(false);
  const [isRefreshCardsModalVisible, setRefreshCardsModalVisible] = useState(false);

  const items = [
    { label: <Link to="/">Dashboard</Link>, key: '/', icon: <BarChartOutlined /> },
    { label: <Link to="/auctions">Auctions</Link>, key: '/auctions', icon: <ClockCircleOutlined /> },
    { label: <Link to="/market">Market</Link>, key: '/market', icon: <ShoppingCartOutlined /> },
    { label: <Link to="/all">All Cards</Link>, key: '/all', icon: <AppstoreOutlined /> },
    {
      label: 'Top Players',
      key: 'topPlayers',
      icon: <OrderedListOutlined />,
      children: [
        {
          label: <Link to="/top">Football</Link>,
          key: '/top',
        },
        {
          label: <Link to="/nba/top">NBA</Link>,
          key: '/nba/top',
        },
      ],
    },
  ];

  if (location?.pathname?.startsWith('/manager/')) {
    const managerSlug = location.pathname.split('/')[2];
    items.push({
      label: 'Manager',
      key: 'manager',
      icon: <UserOutlined />,
      children: [
        {
          label: <Link to={`/manager/${managerSlug}/cards`}>Football Cards</Link>,
          key: `/manager/${managerSlug}/cards`,
        },
        {
          label: <Link to={`/manager/${managerSlug}/nba/lineup`}>NBA Lineup</Link>,
          key: `/manager/${managerSlug}/nba/lineup`,
        },
        {
          label: <Link to={`/manager/${managerSlug}/cards`}>Refresh All Cards</Link>,
          key: `/manager/${managerSlug}/cards/refresh`,
        },
      ],
    });
  }

  items.push({ label: 'Search', key: '/search', icon: <SearchOutlined /> });

  const onMenuOptionClicked = ({ key }) => {
    if (key === '/search') {
      setSearchModalVisible(true);
    } else if (key.startsWith('/manager/') && key.endsWith('/cards/refresh')) {
      setRefreshCardsModalVisible(true);
    }
  };

  return (
    <>
      <AntMenu
        theme="dark"
        selectedKeys={[location.pathname]}
        mode="horizontal"
        items={items}
        onClick={onMenuOptionClicked}
        subMenuCloseDelay={0.01}
      />
      <SearchModal
        isVisible={isSearchModalVisible}
        setVisible={setSearchModalVisible}
      />
      <RefreshCardsModal
        isVisible={isRefreshCardsModalVisible}
        setVisible={setRefreshCardsModalVisible}
        managerSlug={location.pathname?.split('/')?.[2]}
      />
    </>
  );
};

export default withData(Menu);
