import React from 'react';
import { Tag } from 'antd';

import Score from './Score';
import { getColorForScore } from '../utils/score';

function GameWeekTile(props) {
  const { title, value, color } = props;
  return (
    <span className="sa-gw-tile">
      <Tag
        key={Math.random() * 1000000}
        color="rgb(239, 239, 239)"
      >
        <span style={{ color: 'rgba(0, 0, 0, 0.55)' }}>{title}</span>
      </Tag>
      <Tag
        key={Math.random() * 1000000}
        color={color}
      >
        <span style={{ align: 'center' }}>{value}</span>
      </Tag>
    </span>
  );
}

function GameWeekTiles({ data }) {
  // show last 8 GWs
  const tiles = data.scores
    ? data.scores.slice(-8).map(({ index, value }) => (
      <GameWeekTile
        key={`GW${index}Tile-${Math.random()}`}
        title={`GW${index}`}
        value={value === null ? <span className="sa-gw-dnp-text">DNP</span> : value}
        color={getColorForScore(value)}
      />
    ))
    : null;

  return (
    <div className="sa-gw-tiles">
      {tiles}
    </div>
  );
}

function GameWeekScores(props) {
  const { data, highlightScore } = props;

  if (!data) {
    return null;
  }

  return (
    <>
      <Score
        stats={{
          ...data,
          scores: data.scores.map((item) => item.value),
        }}
        highlight={highlightScore ? highlightScore === 'gameWeek' : highlightScore}
        perGames
        fixed
      />
      <GameWeekTiles data={data} />
    </>
  );
}

export default GameWeekScores;
