import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from '@reach/router';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import withData from '../store/withData';
import { getRequest } from '../utils/api';
import NbaLineup from '../components/NbaLineup';
import Filters from '../components/Filters';
import { ViewTypes, generateParameters } from '../utils/filter';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const NbaPlayersLineupView = (props) => {
  const { dataContext } = props;
  const { slug } = useParams();

  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedScoresSort, setSelectedScoresSort] = useState('last10');

  const prepareParams = useCallback(() => {
    let params = '';

    const filterParams = generateParameters(ViewTypes.MANAGER_NBA_LINEUP, dataContext);

    if (filterParams.length) {
      params = `?${filterParams.join('&')}`;
    }

    return params;
  }, [dataContext]);

  useEffect(() => {
    async function loadTopPlayersData() {
      setLoading(true);
      const params = prepareParams(0);
      const data = await getRequest(`managers/${slug}/nba/lineup${params}`);

      setSelectedScoresSort(dataContext.nbaScoresSortSelectValue);

      dataContext.setTopNbaPlayersData(data || { items: [] });
      setLoading(false);
    }
    if (update) {
      setUpdate(false);
      loadTopPlayersData();
    }
  }, [update, dataContext, slug, prepareParams]);

  document.title = `SorareAce - NBA Lineup Builder for ${slug}`;

  return (
    <div className="sa-auction-view">
      <Spin indicator={LoadingIcon} spinning={loading}>
        <Filters dataContext={dataContext} setUpdate={setUpdate} view={ViewTypes.MANAGER_NBA_LINEUP} type="market/listed" />
        <div className="sa-scores-table">
          <NbaLineup
            data={dataContext.topNbaPlayersData.items}
            selectedScoresSort={selectedScoresSort}
          />
        </div>
      </Spin>
    </div>
  );
};

export default withData(NbaPlayersLineupView);
