import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal, AutoComplete, Avatar,
} from 'antd';

import { FlagIcon } from 'react-flag-kit';

import { getRequest } from '../utils/api';

function SearchModal(props) {
  const { isVisible, setVisible } = props;

  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState([]);

  const [update, setUpdate] = useState(false);

  const searchTextInput = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const prepareOptions = (data) => {
    const result = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of data.items) {
      const value = (
        <div className="sa-player-search-row">
          <div style={{ marginRight: '12px' }}>
            <Avatar shape="square" size="large">{item.name.substring(0, 2)}</Avatar>
          </div>
          <div>
            <div className="sa-player-search-row">
              <span className="sa-player-search-name sa-player-search-row">
                <span style={{ marginRight: '6px' }}>{item.name}</span>
                {item.countryCode && (
                  <FlagIcon code={item.countryCode} width={21} height={14} />
                )}
              </span>
              <span className="sa-player-search-club sa-player-search-row">
                <span style={{ margin: '0 4px 0 8px' }}>{`(${item.clubName || 'No club)'}`}</span>
                {item.clubCountryCode && (
                  <FlagIcon code={item.clubCountryCode} width={18} height={12} />
                )}
                {item.clubCountryCode && <>)</>}
              </span>
            </div>
            <div className="sa-player-search-position-age">
              {`${item.position}, ${item.age} years`}
            </div>
          </div>
        </div>
      );

      result.push({ slug: item.slug, value });
    }

    setOptions(result);
  };

  useEffect(() => {
    async function loadData() {
      const data = await getRequest(`/players/search?offset=0&limit=10&searchText=${searchText}`);
      prepareOptions(data);
    }
    if (update && isVisible) {
      setUpdate(false);
      loadData();
    }
  }, [update, isVisible, searchText]);

  function onCancelClick() {
    setVisible(false);
  }

  const onChangeSearchText = (newSearchText) => {
    setSearchText(newSearchText);
    if (newSearchText.length > 2) {
      setUpdate(true);
    }
  };

  const onSelectPlayer = (value, selectedItem) => {
    window.open(`/player/${selectedItem.slug}`, '_blank', 'noopener,noreferrer');
  };

  return (
    isVisible
    && (
      <Modal
        open={isVisible}
        onCancel={onCancelClick}
        destroyOnClose
        footer={null}
        closable={false}
      >
        <AutoComplete
          options={options}
          style={{
            width: 472,
          }}
          onSelect={onSelectPlayer}
          onSearch={onChangeSearchText}
          placeholder="Search for players"
          ref={searchTextInput}
        />
      </Modal>
    )
  );
}

export default SearchModal;
