import React from 'react';

import Score from './Score';

function NbaScores(props) {
  const {
    data, highlightScore, showPercentage = false, fixed = true,
  } = props;

  if (!data) {
    return null;
  }

  return (
    <>
      <Score
        stats={data.last10}
        highlight={highlightScore ? highlightScore === 'last10' : highlightScore}
        showPercentage={showPercentage}
        fixed={fixed}
      />
      {data.last20.games > data.last10.games && (
      <Score
        stats={data.last20}
        highlight={highlightScore ? highlightScore === 'last20' : highlightScore}
        showPercentage={showPercentage}
        fixed={fixed}
      />
      )}
      {data.last40.games > data.last20.games && (
      <Score
        stats={data.last40}
        highlight={highlightScore ? highlightScore === 'last40' : highlightScore}
        showPercentage={showPercentage}
        fixed={fixed}
      />
      )}
      {data.all.games > data.last40.games && (
      <Score
        stats={data.all}
        highlight={highlightScore ? highlightScore === 'all' : highlightScore}
        showPercentage={showPercentage}
        fixed={fixed}
      />
      )}
    </>
  );
}

export default NbaScores;
