import React, { useState } from 'react';
import {
  Table, Switch, Tag, Card,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FlagIcon } from 'react-flag-kit';

import NbaScores from './NbaScores';
import GameWeekScores from './GameWeekScores';

import { getColorForScore } from '../utils/score';

function NbaLineup({ data, selectedScoresSort }) {
  const [selectedChampionPlayers] = useState(new Map());
  const [championCount, setChampionCount] = useState(0);
  const [championPoints, setChampionPoints] = useState(120);
  const [championValues, setChampionValues] = useState([]);

  /* const [selectedConferencePlayers] = useState(new Map());
  const [conferenceCount, setConferenceCount] = useState(0);
  const [conferencePoints, setConferencePoints] = useState(120);
  const [conferenceValues, setConferenceValues] = useState([]); */

  const [selectedContenderPlayers] = useState(new Map());
  const [contenderCount, setContenderCount] = useState(0);
  const [contenderPoints, setContenderPoints] = useState(110);

  const [selectedUnderdogPlayers] = useState(new Map());
  const [underdogCount, setUnderdogCount] = useState(0);
  const [underdogPoints, setUnderdogPoints] = useState(60);

  const [currentPage, setCurrentPage] = useState(1);

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const onChangeChampion = (checked, points, slug) => {
    selectedChampionPlayers.set(slug, checked);

    let newCount = championCount;
    let newValue = championPoints;
    if (checked) {
      newCount += 1;
      championValues.push(points);
      const max = Math.max.apply(null, championValues);
      newValue = 120 + max;
      // eslint-disable-next-line no-restricted-syntax
      for (const v of championValues) {
        newValue -= v;
      }
    } else {
      newCount -= 1;
      const newValues = [];
      newValue = 120;
      let wasRemoved = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const v of championValues) {
        if (v === points && !wasRemoved) {
          wasRemoved = true;
          // eslint-disable-next-line no-continue
          continue;
        }
        newValues.push(v);
        newValue -= v;
      }
      const max = newValues.length ? Math.max.apply(null, newValues) : 0;
      newValue += max;
      setChampionValues(newValues);
    }
    setChampionCount(newCount);
    setChampionPoints(newValue);
  };

  const onChangeContender = (checked, points, slug) => {
    selectedContenderPlayers.set(slug, checked);

    const newCount = contenderCount + ((checked ? 1 : -1));
    const newValue = contenderPoints - (checked ? points : points * (-1));
    setContenderCount(newCount);
    setContenderPoints(newValue);
  };

  /* const onChangeConference = (checked, points, slug) => {
    selectedConferencePlayers.set(slug, checked);

    let newCount = conferenceCount;
    let newValue = conferencePoints;
    if (checked) {
      newCount += 1;
      conferenceValues.push(points);
      const max = Math.max.apply(null, conferenceValues);
      newValue = 120 + max;
      // eslint-disable-next-line no-restricted-syntax
      for (const v of conferenceValues) {
        newValue -= v;
      }
    } else {
      newCount -= 1;
      const newValues = [];
      newValue = 120;
      let wasRemoved = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const v of conferenceValues) {
        if (v === points && !wasRemoved) {
          wasRemoved = true;
          // eslint-disable-next-line no-continue
          continue;
        }
        newValues.push(v);
        newValue -= v;
      }
      const max = newValues.length ? Math.max.apply(null, newValues) : 0;
      newValue += max;
      setConferenceValues(newValues);
    }
    setConferenceCount(newCount);
    setConferencePoints(newValue);
  }; */

  const onChangeUnderdog = (checked, points, slug) => {
    selectedUnderdogPlayers.set(slug, checked);

    const newCount = underdogCount + ((checked ? 1 : -1));
    const newValue = underdogPoints - (checked ? points : points * (-1));
    setUnderdogCount(newCount);
    setUnderdogPoints(newValue);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (v, r, index) => ((currentPage - 1) * 10 + index + 1),
    },
    {
      title: 'Player',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (value, item) => (
        <>
          <a href={`/nba/player/${item.slug}`} target="_blank" rel="noreferrer">
            <div className="sa-player-search-row">
              <span style={{ marginRight: '10px', fontSize: '18px', fontWeight: 'bold' }}>{value}</span>
              {item.countrySlug && (
                <FlagIcon code={item.countrySlug} width={24} height={16} />
              )}
            </div>
          </a>
          <div style={{ marginRight: '8px', fontSize: '14px' }}>
            <div>{`${item.age} years`}</div>
            <div>{`(${(new Date(item.birthDate)).toLocaleDateString()})`}</div>
          </div>
        </>
      ),
    },
    {
      title: 'Club',
      dataIndex: 'clubName',
      key: 'clubName',
      render: (clubName, item) => (
        <div className="sa-player-search-row">
          {item.clubPictureUrl && (
            <img src={item.clubPictureUrl} alt="club emblem" width="30" />
          )}
          <span style={{ marginLeft: '8px', fontSize: '16px', fontWeight: 'bold' }}>
            {clubName || 'No club data'}
          </span>
        </div>
      ),
    },
    {
      title: 'Game Weeks Scores',
      dataIndex: 'stats',
      key: 'gameWeek',
      render: (stats) => (
        <div style={{ overflowWrap: 'normal', marginRight: '10px' }}>
          <GameWeekScores data={stats.gameWeek} highlightScore={selectedScoresSort} />
        </div>
      ),
      width: 300,
    },
    {
      title: 'Games Scores',
      dataIndex: 'stats',
      key: 'scores',
      render: (stats) => (
        <div style={{ overflowWrap: 'normal', marginRight: '10px' }}>
          <NbaScores data={stats} highlightScore={selectedScoresSort} />
        </div>
      ),
      width: 200,
    },
    {
      title: 'Sorare L10 Average',
      dataIndex: 'stats',
      key: 'sorareL10Average',
      render: (stats) => (
        <Tag
          key={Math.random() * 1000000}
          color={getColorForScore(stats?.sorareL10Average)}
          style={{ fontSize: '14px' }}
        >
          {stats?.sorareL10Average ? stats?.sorareL10Average.toFixed(1) : 'N/A'}
        </Tag>
      ),
    },
    {
      title: 'Difference from L10 Average',
      dataIndex: 'stats',
      key: 'diff',
      render: (stats) => {
        const gameWeekAverage = stats?.gameWeek?.averageScore || 0;
        const l10Average = stats?.isL10AverageCorrect === false
          ? stats?.sorareL10Average || 0 : stats?.last10?.averageScore || 0;
        const diff = gameWeekAverage - l10Average;
        return (
          <div className="sa-nba-diff-row">
            <Tag
              key={Math.random() * 1000000}
              color={getColorForScore(gameWeekAverage)}
              style={{ fontSize: '14px' }}
            >
              {gameWeekAverage.toFixed(1)}
            </Tag>
            <span style={{ margin: '0px 8px 0px 0px' }}>-</span>
            <Tag
              key={Math.random() * 1000000}
              color={getColorForScore(l10Average)}
              style={{ fontSize: '14px' }}
            >
              {l10Average.toFixed(1)}
            </Tag>
            <span style={{ margin: '0px 8px 0px 0px' }}>=</span>
            <Tag
              key={Math.random() * 1000000}
              color={diff > 0 ? 'green' : 'red'}
              style={{ fontSize: '16px' }}
            >
              {diff.toFixed(1)}
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'Games Next GameWeek',
      dataIndex: 'stats',
      key: 'nextGwGamesCount',
      render: (stats) => (
        <Tag
          key={Math.random() * 1000000}
          // eslint-disable-next-line no-nested-ternary
          color={stats?.nextGwGamesCount > 0 ? stats?.nextGwGamesCount >= 2 ? 'green' : 'yellow' : 'red'}
          style={{ fontSize: '16px' }}
        >
          {stats?.nextGwGamesCount || '0'}
        </Tag>
      ),
    },
    {
      title: 'Champion',
      dataIndex: 'stats',
      key: 'champion',
      render: (stats, item) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={selectedChampionPlayers.get(item.slug) === true}
          onChange={(checked) => (
            onChangeChampion(checked, stats?.sorareL10Average, item.slug))}
        />
      ),
    },
    {
      title: 'Contender',
      dataIndex: 'stats',
      key: 'contender',
      render: (stats, item) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={selectedContenderPlayers.get(item.slug) === true}
          onChange={(checked) => (
            onChangeContender(checked, stats?.sorareL10Average, item.slug))}
        />
      ),
    },
    /* {
      title: 'Conference',
      dataIndex: 'stats',
      key: 'conference',
      render: (stats, item) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={selectedConferencePlayers.get(item.slug) === true}
          onChange={(checked) => (
            onChangeConference(checked, stats?.sorareL10Average, item.slug))}
        />
      ),
    }, */
    {
      title: 'Underdog',
      dataIndex: 'stats',
      key: 'underdog',
      render: (stats, item) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={selectedUnderdogPlayers.get(item.slug) === true}
          onChange={(checked) => (
            onChangeUnderdog(checked, stats?.sorareL10Average, item.slug))}
        />
      ),
    },
  ];

  return (
    <>
      <div className="sa-lineup-stats">
        <Card title="Champion">
          {`Remaining points: ${championPoints} (selected ${championCount} players)`}
        </Card>
        <Card title="Contender">
          {`Remaining points: ${contenderPoints} (selected ${contenderCount} players)`}
        </Card>
        {/* <Card title="Western/Eastern Conference">
          {`Remaining points: ${conferencePoints} (selected ${conferenceCount} players)`}
        </Card> */}
        <Card title="Underdog">
          {`Remaining points: ${underdogPoints} (selected ${underdogCount} players)`}
        </Card>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={{
          showSizeChanger: false,
          onChange: onChangePage,
        }}
      />
    </>
  );
}

export default NbaLineup;
