import React from 'react';

import { Tooltip } from 'antd';

function CardBonus(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  const content = (
    <div className="sa-card-bonus-breakdown">
      {data.breakdown.scarcity > 0 && (
        <div className="sa-card-bonus-breakdown-row">
          <span>Rarity Bonus:</span>
          <span className="sa-card-bonus-breakdown-value">{`+${data.breakdown.scarcity.toFixed(1)}%`}</span>
        </div>
      )}
      {data.breakdown.season > 0 && (
        <div className="sa-card-bonus-breakdown-row">
          <span>Season Bonus:</span>
          <span className="sa-card-bonus-breakdown-value">{`+${data.breakdown.season.toFixed(1)}%`}</span>
        </div>
      )}
      {data.breakdown.xp > 0 && (
        <div className="sa-card-bonus-breakdown-row">
          <span>XP Bonus:</span>
          <span className="sa-card-bonus-breakdown-value">{`+${data.breakdown.xp.toFixed(1)}%`}</span>
        </div>
      )}
      {data.breakdown.collection > 0 && (
        <div className="sa-card-bonus-breakdown-row">
          <span>Collection Bonus:</span>
          <span className="sa-card-bonus-breakdown-value">{`+${data.breakdown.collection.toFixed(1)}%`}</span>
        </div>
      )}
      {!data.breakdown.scarcity && !data.breakdown.season && !data.breakdown.xp && ('No Bonus')}
    </div>
  );

  return (
    <div className="sa-card-bonus">
      <Tooltip title={content} color="white"><span>{`+${data.value.toFixed(1)}%`}</span></Tooltip>
    </div>
  );
}

export default CardBonus;
