import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useParams } from '@reach/router';
import { Button, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getRequest, postRequest } from '../utils/api';
import withData from '../store/withData';
import ManagerCard from '../components/ManagerCard';
import Filters from '../components/Filters';
import { ViewTypes, generateParameters } from '../utils/filter';
import { useScreenDimensions } from '../utils/screen';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ManagerCardsView(props) {
  const { dataContext } = props;
  const { slug } = useParams();

  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  const { width } = useScreenDimensions();
  const cardsFitInLine = Math.floor(width / 236);

  const prepareParams = useCallback((offset) => {
    const limit = cardsFitInLine * 2 - (offset % cardsFitInLine);
    let params = `?offset=${offset}&limit=${limit}`;

    const filterParams = generateParameters(ViewTypes.MANAGER_CARDS, dataContext);

    if (filterParams.length) {
      params += `&${filterParams.join('&')}`;
    }

    return params;
  }, [dataContext, cardsFitInLine]);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const params = prepareParams(0);
      const data = await getRequest(`managers/${slug}/cards${params}`);
      dataContext.setManagerCards(data || { items: [] });
      setLoading(false);
    }
    if (update) {
      setUpdate(false);
      loadData();
    }
  }, [update, dataContext, slug, prepareParams]);

  const thisRef = useRef(null);

  const [api, contextHolder] = notification.useNotification();

  const onRefreshButtonClick = async (data) => {
    const result = await postRequest(`managers/${slug}/cards/${data.cardSlug}/update`);

    if (result) {
      api.success({
        message: 'Success',
        description: `Your card ${data.cardName} has been marked for update.`,
        placement: 'bottomRight',
      });
    } else {
      api.warning({
        message: 'Try again tomorrow',
        description: `Your card ${data.cardName} has already been updated today.`,
        placement: 'bottomRight',
      });
    }
  };

  const cards = dataContext.managerCards.items.map((item, index) => (
    <ManagerCard
      key={item.cardSlug}
      data={item}
      reference={(index === dataContext.managerCards.items.length - 1) ? thisRef : null}
      onRefresh={onRefreshButtonClick}
    />
  ));

  const onLoadMoreButtonClick = useCallback(async () => {
    setLoading(true);
    const params = prepareParams(dataContext.managerCards.items.length);
    const data = await getRequest(`managers/${slug}/cards${params}`);
    if (data && data.items && data.items.length) {
      const newItems = dataContext.managerCards.items.concat(data.items);
      dataContext.setManagerCards({ items: newItems });
    }
    setLoading(false);
  }, [dataContext, slug, prepareParams]);

  useEffect(() => {
    if (!thisRef.current) {
      return null;
    }

    let observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onLoadMoreButtonClick();
          observer = observer.disconnect();
        }
      }),
    );
    observer.observe(thisRef.current);

    return () => { observer = observer && observer.disconnect(); return observer; };
  }, [thisRef, onLoadMoreButtonClick]);

  document.title = `Cards owned by Manager ${slug}`;

  return (
    <div className="sa-auction-view">
      {contextHolder}
      <Spin indicator={LoadingIcon} spinning={loading}>
        <Filters dataContext={dataContext} setUpdate={setUpdate} view={ViewTypes.MANAGER_CARDS} type="market/listed" />
        <div className="sa-cards">
          {cards.length ? cards : 'No cards found'}
        </div>
        <Button block onClick={onLoadMoreButtonClick}>Load more</Button>
      </Spin>
    </div>
  );
}

export default withData(ManagerCardsView);
