import React from 'react';
import { Spin } from 'antd';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import { LoadingOutlined } from '@ant-design/icons';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Chart = ({
  data, isLoading, width, height,
  xAxisName, yAxisName, yAxisRightName,
  yAxisColor = 'rgba(85, 96, 123, 0.7)', yAxisRightColor = 'rgba(54, 162, 235, 1)',
}) => {
  let content = 'No Data';
  const leftYAxis = (
    <Line
      yAxisId="left"
      dataKey={yAxisName}
      name={yAxisName}
      stroke={yAxisColor}
      strokeWidth={2}
      dot={{ r: 1 }}
      activeDot={{ r: 3 }}
    />
  );
  const rightYAxis = (
    <Line
      yAxisId="right"
      dataKey={yAxisRightName}
      name={yAxisRightName}
      stroke={yAxisRightColor}
      strokeWidth={2}
      dot={{ r: 1 }}
      activeDot={{ r: 3 }}
    />
  );
  if (data.length > 0) {
    content = (
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 10, right: 10, left: 10, bottom: 10,
        }}
      >
        <XAxis dataKey={xAxisName} />
        <YAxis yAxisId="left" orientation="left" stroke={yAxisColor} />
        <YAxis yAxisId="right" orientation="right" stroke={yAxisRightColor} />
        <CartesianGrid stroke="rgba(85, 96, 123, 0.10)" />
        <Tooltip />
        <Legend />
        {leftYAxis}
        {rightYAxis}
      </LineChart>
    );
  }

  return (
    <Spin indicator={LoadingIcon} spinning={isLoading}>
      {content}
    </Spin>
  );
};

export default Chart;
