import React, { useState, useEffect } from 'react';
import { useParams } from '@reach/router';
import {
  Card, Tabs, Button, Space, Spin, Select,
} from 'antd';
import Icon, {
  OrderedListOutlined, ClockCircleOutlined, AppstoreOutlined, LoadingOutlined, GiftOutlined,
} from '@ant-design/icons';
import {
  SorareData, Transfermarkt, Google, Twitter,
} from '../components/CustomSvgIcons';

import withData from '../store/withData';
import { getRequest } from '../utils/api';

import ListedCardNoScores from '../components/ListedCardNoScores';
import AuctionCardNoScores from '../components/AuctionCardNoScores';
import PlayerProfileCard from '../components/PlayerProfileCard';
import PriceBlock from '../components/PriceBlock';
import Scores from '../components/Scores';
import ScoresList from '../components/ScoresList';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ScoresCardTitle = (props) => {
  const { data, perGames } = props;

  return (
    <>
      <div className="sa-scores-card-title">
        {data}
      </div>
      <div className="sa-scores-card-additional-title">
        {`(% of ${perGames ? 'games' : 'minutes'})`}
      </div>
    </>
  );
};

const ScoresBlock = (props) => {
  const { data, title } = props;

  return (
    <div className="sa-scores-block">
      <div className="sa-scores-card">
        <Card title={<ScoresCardTitle data={title} perGames />}>
          <div className="sa-scores-card-bottom">
            <Scores data={data} perGame />
          </div>
        </Card>
      </div>
      <div className="sa-scores-card">
        <Card title={<ScoresCardTitle data={title} perGames={false} />}>
          <div className="sa-scores-card-bottom">
            <Scores data={data} perGames={false} />
          </div>
        </Card>
      </div>
    </div>
  );
};

const ScoresBlocks = (props) => {
  const { data } = props;

  return (
    <div className="sa-scores-blocks">
      <ScoresBlock data={data.total} title="Total stats" />
      <ScoresBlock data={data.club} title="Stats for the Club " />
      <ScoresBlock data={data.nationalTeam} title="Stats for the National Team" />
    </div>
  );
};

const PricesBlock = (props) => {
  const { data } = props;

  return (
    <div className="sa-prices-card">
      <Card>
        <PriceBlock data={data.limited} type="limited" />
        <PriceBlock data={data.rare} type="rare" />
        <PriceBlock data={data.super} type="super_rare" />
        <PriceBlock data={data.unique} type="unique" />
      </Card>
    </div>
  );
};

const SearchButton = (props) => {
  const { href, icon, text } = props;

  return (
    <Button
      size="large"
      href={href}
      target="_blank"
    >
      <div className="sa-player-profile-button-content">
        {icon}
        <span style={{ paddingLeft: '10px' }}>{text}</span>
      </div>
    </Button>
  );
};

const RaritySelect = ({ value, onChange }) => (
  <Select
    style={{ width: 200 }}
    placeholder="Select a rarity to show"
    onChange={onChange}
    value={value}
  >
    <Select.Option value="limited">Limited</Select.Option>
    <Select.Option value="rare">Rares</Select.Option>
    <Select.Option value="super_rare">Super Rares</Select.Option>
    <Select.Option value="unique">Uniques</Select.Option>
  </Select>
);

const PlayerView = (props) => {
  const { dataContext } = props;

  const [loading, setLoading] = useState(false);
  const [selectedRarity, setSelectedRarity] = useState('rare');
  const [auctions, setAuctions] = useState(null);
  const [listedCards, setListedCards] = useState(null);
  const [rewards, setRewardCards] = useState(null);
  const [lastSlug, setLastSlug] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    async function loadPlayerData() {
      setLoading(true);
      const data = await getRequest(`players/${slug}`);
      dataContext.setPlayerData(data || {
        player: {}, prices: {}, scores: {},
      });
      document.title = `Player Profile - ${data.player.name}`;
      setLoading(false);
    }
    if (slug !== lastSlug) {
      setLastSlug(slug);
      loadPlayerData();
    }
  }, [dataContext, slug, lastSlug]);

  const {
    player, prices, scores,
  } = dataContext.playerData;

  async function loadTabCards(tabName, rarity) {
    setLoading(true);

    switch (tabName) {
      case 'auctions': {
        const data = await getRequest(`players/${slug}/auctions?rarity=${rarity}&offset=0&limit=14`);
        if (data) {
          setAuctions(data);
        }
        break;
      }

      case 'listed': {
        const data = await getRequest(`players/${slug}/listed?rarity=${rarity}&offset=0&limit=14`);
        if (data) {
          setListedCards(data);
        }
        break;
      }

      case 'rewards': {
        const data = await getRequest(`players/${slug}/rewards?rarity=${rarity}&offset=0&limit=14`);
        if (data) {
          setRewardCards(data);
        }
        break;
      }

      default:
        // eslint-disable-next-line
        console.error(`Unknown Tab Type: ${tabName}`);
        break;
    }

    setLoading(false);
  }

  function onTabChange(tabName) {
    loadTabCards(tabName, selectedRarity);
  }

  function onRarityChange(tabName, rarity) {
    setSelectedRarity(rarity);

    loadTabCards(tabName, rarity);
  }

  function addBestMarketPrice(data) {
    const result = data;

    switch (selectedRarity) {
      case 'limited':
        result.bestMarketPrice = prices.limited?.cheapestPrice?.price;
        return result;

      case 'rare':
        result.bestMarketPrice = prices.rare?.cheapestPrice?.price;
        return result;

      case 'super_rare':
        result.bestMarketPrice = prices.super?.cheapestPrice?.price;
        return result;

      case 'unique':
        result.bestMarketPrice = prices.unique?.cheapestPrice?.price;
        return result;

      default:
        break;
    }

    return result;
  }

  const marketCards = listedCards ? listedCards.map((item) => (
    <ListedCardNoScores key={item.cardSlug} data={addBestMarketPrice(item)} />
  )) : [];

  const auctionCards = auctions ? auctions.map((item) => (
    <AuctionCardNoScores key={item.cardSlug} data={item} />
  )) : [];

  const rewardCards = rewards ? rewards.map((item) => (
    <ListedCardNoScores key={item.cardSlug} data={item} />
  )) : [];

  return (
    <Spin indicator={LoadingIcon} spinning={loading}>
      <div className="sa-player-profile-buttons">
        <Space>
          <SearchButton
            href={`https://www.soraredata.com/playerSearch/${player.name}`}
            icon={<Icon component={SorareData} {...props} />}
            text="Search in SorareData"
          />
          <SearchButton
            href={`https://www.transfermarkt.com/schnellsuche/ergebnis/schnellsuche?query=${player.name}`}
            icon={<Icon component={Transfermarkt} {...props} />}
            text="Search in Transfermarkt"
          />
          <SearchButton
            href={`https://www.google.com/search?q=${player.name}&tbm=nws`}
            icon={<Icon component={Google} {...props} />}
            text="Search in Google"
          />
          <SearchButton
            href={`https://twitter.com/search?q=${player.name}&src=typed_query`}
            icon={<Icon component={Twitter} {...props} />}
            text="Search in Twitter"
          />
        </Space>
      </div>

      <div className="sa-player-profile">
        <PlayerProfileCard data={player} />
        <PricesBlock data={prices} />
        <ScoresBlocks data={scores} />
      </div>

      <Tabs defaultActiveKey="priceStats" centered onChange={onTabChange}>
        <Tabs.TabPane
          tab={(
            <span>
              <OrderedListOutlined />
              Scores List
            </span>
          )}
          key="priceStats"
        >
          <div className="sa-scores-table">
            <ScoresList data={scores.all || []} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={(
            <span>
              <AppstoreOutlined />
              Cheapest Listed Cards
            </span>
          )}
          key="listed"
        >
          <div className="sa-player-profile-rarity-select">
            <RaritySelect
              value={selectedRarity}
              onChange={(rarity) => onRarityChange('listed', rarity)}
            />
          </div>
          <div className="sa-cards">
            {marketCards.length ? marketCards : 'No listed cards'}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={(
            <span>
              <ClockCircleOutlined />
              Recent Auctions
            </span>
          )}
          key="auctions"
        >
          <div className="sa-player-profile-rarity-select">
            <RaritySelect
              value={selectedRarity}
              onChange={(rarity) => onRarityChange('auctions', rarity)}
            />
          </div>
          <div className="sa-cards">
            {auctionCards.length ? auctionCards : 'No auctions'}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={(
            <span>
              <GiftOutlined />
              Rewards
            </span>
          )}
          key="rewards"
        >
          <div className="sa-player-profile-rarity-select">
            <RaritySelect
              value={selectedRarity}
              onChange={(rarity) => onRarityChange('rewards', rarity)}
            />
          </div>
          <div className="sa-cards">
            {rewardCards.length ? rewardCards : 'No rewards'}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Spin>
  );
};

export default withData(PlayerView);
