import { Router, Location } from '@reach/router';
import { Layout } from 'antd';

import Menu from '../components/Menu';
import AuctionsView from './AuctionsView';
import DashboardView from './DashboardView';
import MarketView from './MarketView';
import AllCardsView from './AllCardsView';
import SettingsView from './SettingsView';
import TopPlayersView from './TopPlayersView';
import TopNbaPlayersView from './TopNbaPlayersView';
import PlayerView from './PlayerView';
import NbaPlayerView from './NbaPlayerView';
import ManagerCardsView from './ManagerCardsView';
import NbaPlayersLineupView from './NbaPlayersLineupView';

import '../styles/App.css';

const { Header, Content } = Layout;

function App() {
  return (
    <Layout>
      <Header>
        <Location>
          {({ location }) => (
            <Menu location={location} />
          )}
        </Location>
      </Header>
      <Content>
        <Router>
          <DashboardView path="/" />
          <AuctionsView path="/auctions" />
          <MarketView path="/market" />
          <AllCardsView path="/all" />
          <SettingsView path="/settings" />
          <TopPlayersView path="/top" />
          <TopNbaPlayersView path="/nba/top" />
          <PlayerView path="/player/:slug" />
          <NbaPlayerView path="/nba/player/:slug" />
          <ManagerCardsView path="/manager/:slug/cards" />
          <NbaPlayersLineupView path="/manager/:slug/nba/lineup" />
        </Router>
      </Content>
      {/* <Footer>Copyright 2022 SorareAce.</Footer> */}
    </Layout>
  );
}

export default App;
