import axios from 'axios';

const BACKEND = 'https://api.sorareace.com';
// const BACKEND = 'http://localhost:3007';

const getRequest = async (path) => {
  try {
    const result = await axios.get(`${BACKEND}/v1/${path}`);

    return result.data;
  } catch (err) {
    if (err.response) {
      // eslint-disable-next-line no-console
      console.error(err.response.status, err.response.data.error);
    }
  }

  return null;
};

const postRequest = async (path) => {
  try {
    const result = await axios.post(`${BACKEND}/v1/${path}`);

    return result.data;
  } catch (err) {
    if (err.response) {
      // eslint-disable-next-line no-console
      console.error(err.response.status, err.response.data.error);
    }
  }

  return null;
};

export { getRequest, postRequest };
