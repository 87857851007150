import React from 'react';
import { Row, Col, Progress } from 'antd';

import { getColorForScore } from '../utils/score';

function DetailedScoreHistory(props) {
  const {
    stats, perGames, showPercentage, fixed,
  } = props;
  let percentage = null;
  if (showPercentage) {
    percentage = perGames ? (stats.percentageGames || 0) : (stats.percentageMinutes || 0);
  }
  const fragmentWidth = 100 / stats.scores.length;

  const scoreFragments = stats.scores ? stats.scores.map((item) => (
    <div
      key={Math.random() * 1000000}
      className="ant-progress-bg"
      style={{ width: `${fragmentWidth}%`, height: '5px', background: getColorForScore(item) }}
    />
  )) : <></>;

  return (
    <span>
      <div className="ant-progress ant-progress-line ant-progress-show-info ant-progress-small">
        <div className="ant-progress-outer">
          <div className="ant-progress-inner" style={{ display: 'flex', width: fixed ? '100px' : '100%' }}>
            {scoreFragments}
          </div>
        </div>
        {showPercentage && (
        <span className="ant-progress-text" title={`${percentage.toFixed(0)}%`}>
          {percentage.toFixed(0)}
          %
        </span>
        )}
      </div>
    </span>
  );
}

function ScoreHistory(props) {
  const { stats, perGames } = props;
  const percentage = perGames ? (stats.percentageGames || 0) : (stats.percentageMinutes || 0);
  return (
    <Progress
      percent={percentage}
      format={(percent) => (`${percent.toFixed(0)}%`)}
      size="small"
      strokeWidth={5}
      strokeColor={getColorForScore(stats.averageScore)}
      status="active"
    />
  );
}

function Score(props) {
  const {
    stats, perGames, highlight, showPercentage = true, detailed = true, fixed = false,
  } = props;

  let scoreFontWeight = 'bold';
  if (highlight) {
    scoreFontWeight = '900';
  } else if (highlight === false) {
    scoreFontWeight = 'normal';
  }

  const name = `L${stats.scores.length}`;

  const average = stats.averageScore?.toFixed(0) || 0;

  const scoreHistory = detailed ? (
    <DetailedScoreHistory
      stats={stats}
      perGames={perGames}
      showPercentage={showPercentage}
      fixed={fixed}
    />
  ) : (
    <ScoreHistory stats={stats} perGames={perGames} fixed={fixed} />
  );

  if (fixed) {
    return (
      <div className="sa-score-row">
        <span style={{ fontWeight: highlight ? 'bold' : 'normal', paddingRight: '8px' }}>
          {name}
        </span>
        <span
          style={{ color: getColorForScore(stats.averageScore || 0), fontWeight: scoreFontWeight, paddingRight: '8px' }}
        >
          {average}
        </span>
        <span>
          {scoreHistory}
        </span>
      </div>
    );
  }

  return (
    <Row gutter={24}>
      <Col span={4} style={{ fontWeight: highlight ? 'bold' : 'normal' }}>
        {name}
      </Col>
      <Col
        span={4}
        style={{ color: getColorForScore(stats.averageScore || 0), fontWeight: scoreFontWeight }}
      >
        {average}
      </Col>
      <Col span={16}>
        {scoreHistory}
      </Col>
    </Row>
  );
}

export default Score;
