import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import { postRequest } from '../utils/api';

function RefreshCardsModal(props) {
  const { isVisible, setVisible, managerSlug } = props;

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    async function loadData() {
      await postRequest(`/managers/${managerSlug}/cards/refresh`);
      setVisible(false);
    }
    if (update && isVisible) {
      setUpdate(false);
      loadData();
    }
  }, [update, isVisible, setVisible, managerSlug]);

  function onOkClick() {
    setUpdate(true);
  }

  function onCancelClick() {
    setVisible(false);
  }

  return (
    isVisible
    && (
      <Modal
        open={isVisible}
        onOk={onOkClick}
        onCancel={onCancelClick}
        destroyOnClose
      >
        You are going to refresh all cards for the active manager. Are you sure?
      </Modal>
    )
  );
}

export default RefreshCardsModal;
