import React from 'react';
import { Row, Col } from 'antd';
import { ClockCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import { getFormattedNumber } from '../utils/number';

function PriceBlock(props) {
  const { data, type, game = 'football' } = props;

  let color;
  let name;

  switch (type) {
    case 'limited':
      color = 'rgb(215, 190, 28)';
      name = 'Limited';
      break;
    case 'rare':
      color = 'rgb(189, 44, 58)';
      name = 'Rare';
      break;
    case 'super_rare':
      color = 'rgb(31, 161, 255)';
      name = 'Super Rare';
      break;
    case 'unique':
      color = 'rgb(95, 47, 3)';
      name = 'Unique';
      break;
    default:
      color = 'black';
      name = 'Unknown';
  }

  let playerUrlStart;

  switch (game) {
    case 'football':
      playerUrlStart = 'https://sorare.com/football/cards/';
      break;
    case 'baseball':
      playerUrlStart = 'https://sorare.com/mlb/cards/';
      break;
    case 'nba':
      playerUrlStart = 'https://sorare.com/nba/cards/';
      break;
    default:
      playerUrlStart = 'https://sorare.com/football/cards/';
      break;
  }

  return (
    <div className="sa-price-row-block">
      <div style={{ color, fontWeight: 'bold' }}>{name}</div>
      <Row gutter={24}>
        <Col
          style={{ color, fontWeight: 'bold' }}
          span={6}
        >
          <ClockCircleOutlined />
        </Col>
        <Col
          span={18}
        >
          {data && data.lastAuction ? (
            <a
              href={`${playerUrlStart}${data.lastAuction.slug}`}
              target="_blank"
              rel="noreferrer"
              style={{ color, fontWeight: 'bold' }}
            >
              Ξ
              {getFormattedNumber(data.lastAuction.price)}
            </a>
          ) : <div style={{ color, fontWeight: 'bold' }}>No Data</div>}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col
          style={{ color, fontWeight: 'bold' }}
          span={6}
        >
          <ShoppingCartOutlined />
        </Col>
        <Col span={18}>
          {data && data.cheapestPrice ? (
            <a
              href={`${playerUrlStart}${data.cheapestPrice.slug}`}
              target="_blank"
              rel="noreferrer"
              style={{ color, fontWeight: 'bold' }}
            >
              Ξ
              {getFormattedNumber(data.cheapestPrice.price)}
            </a>
          ) : <div style={{ color, fontWeight: 'bold' }}>No Data</div>}
        </Col>
      </Row>
    </div>
  );
}

export default PriceBlock;
