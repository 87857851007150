/* eslint-disable no-nested-ternary */
import React from 'react';
import { Table /* Tooltip */ } from 'antd';

import { getColorForScore } from '../utils/score';

const getMatchScoreRenderer = (homeTeam, data) => {
  const playerTeamScore = data.playerTeam === homeTeam ? data.homeTeamScore : data.awayTeamScore;
  const nonPlayerTeamScore = data.playerTeam === homeTeam ? data.awayTeamScore : data.homeTeamScore;

  let color = 'orange';
  if (playerTeamScore > nonPlayerTeamScore) {
    color = 'rgb(44, 204, 137)';
  } else if (playerTeamScore < nonPlayerTeamScore) {
    color = 'red';
  }
  const result = (
    <div className="sa-auction-time-badges-block">
      <span style={{ fontWeight: data.playerTeam === homeTeam ? 'bold' : 'normal', width: '40%', textAlign: 'right' }}>
        {homeTeam}
      </span>
      <span style={{ padding: '0px 10px 0px 10px', color, fontWeight: 'bold' }}>
        {`${data.homeTeamScore} - ${data.awayTeamScore}`}
      </span>
      <span style={{ fontWeight: data.playerTeam === data.awayTeam ? 'bold' : 'normal', width: '40%', textAlign: 'left' }}>
        {data.awayTeam}
      </span>
    </div>
  );

  return result;
};

function ScoresList({ data }) {
  const columns = [
    {
      title: 'Game Week',
      dataIndex: 'gameWeek',
      key: 'gameWeek',
    },
    {
      title: 'Match Date',
      dataIndex: 'matchDate',
      key: 'matchDate',
      render: (matchDate) => (
        (new Date(matchDate)).toLocaleString()
      ),
    },
    {
      title: 'Game Status',
      dataIndex: 'gameStatus',
      key: 'gameStatus',
    },
    {
      title: 'Game',
      dataIndex: 'homeTeam',
      key: 'homeTeam',
      render: getMatchScoreRenderer,
    },
    {
      title: 'Score Value',
      dataIndex: 'value',
      key: 'value',
      render: (value) => (
        <div style={{ color: getColorForScore(value), fontWeight: 'bold' }}>{value}</div>
      ),
    },
  ];

  return (
    <Table dataSource={data} columns={columns} pagination={false} />
  );
}

export default ScoresList;
