import React from 'react';
import { Card, Tooltip } from 'antd';

import { TimeAndBadges } from './CardParts';
import Scores from './Scores';

import { getFormattedNumber } from '../utils/number';

function Prices(props) {
  const { data } = props;

  return (
    <div className="sa-auction-prices-block">
      <Tooltip title="The last auction price">
        <div className="sa-auction-market-price">
          {data.lastAuctionPriceData && (
          <a
            href={`https://sorare.com/football/cards/${data.lastAuctionPriceData.cardSlug}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'rgba(0, 0, 0, 0.85)' }}
          >
            {`Ξ${getFormattedNumber(data.lastAuctionPriceData.price)}`}
          </a>
          )}
          {!data.lastAuctionPriceData && 'No data'}
        </div>
      </Tooltip>
      <Tooltip title="Listed price">
        <a
          href={`https://sorare.com/football/cards/${data.cardSlug}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        >
          <div className="sa-auction-price">
            {data.currentPrice ? `Ξ${getFormattedNumber(data.currentPrice.eth)}` : 'No data'}
          </div>
        </a>
      </Tooltip>
      <Tooltip title="The lowest market price (floor)">
        <div className="sa-auction-market-price">
          {data.bestMarketPriceData && (
          <a
            href={`https://sorare.com/football/cards/${data.bestMarketPriceData.cardSlug}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'rgba(0, 0, 0, 0.85)' }}
          >
            {`Ξ${getFormattedNumber(data.bestMarketPriceData.price)}`}
          </a>
          )}
          {!data.bestMarketPriceData && 'No data'}
        </div>
      </Tooltip>
    </div>
  );
}

function ListedCard(props) {
  const { data } = props;

  return (
    <div className="sa-auction-card-container">
      <Card
        cover={(
          <a href={`/player/${data.playerSlug}`} target="_blank" rel="noreferrer">
            <img
              key={data.slug}
              src={data.pictureUrl}
              alt={data.playerName}
              width={216} // 180
              height={350}
            />
          </a>
        )}
      >
        <div className="sa-auction-card-bottom">
          <TimeAndBadges data={data} />
          <Prices data={data} />
          <Scores data={data.scoreStats} />
        </div>
      </Card>
    </div>
  );
}

export default ListedCard;
