import React from 'react';
import { Card } from 'antd';
import { FlagIcon } from 'react-flag-kit';

function NbaPlayerProfileCard(props) {
  const { data } = props;

  return (
    <div className="sa-player-profile-card">
      <Card>
        <a href={`https://sorare.com/nba/players/${data.slug}`} target="_blank" rel="noreferrer">
          <div className="sa-player-profile-name sa-player-search-row">
            <span style={{ marginRight: '10px' }}>{data.name}</span>
            {data.countryCode && (
              <FlagIcon code={data.countryCode} width={30} height={20} />
            )}
          </div>
        </a>
        <div className="sa-player-profile-club sa-player-search-row">
          {data.clubPictureUrl && (
            <img src={data.clubPictureUrl} alt="club emblem" width="30" />
          )}
          <span style={{ margin: '0 10px 0 10px' }}>{data.clubName || 'No club data'}</span>
        </div>
        <div className="sa-player-profile-position-age">
          {data.position?.replaceAll('NBA_', '').replaceAll(',', ', ')}
        </div>
        <div className="sa-player-profile-position-age">
          {`${data.age} years`}
        </div>
        <div
          className="sa-player-profile-image"
          style={{ backgroundImage: `url(${data.pictureUrl})` }}
        />
      </Card>
    </div>
  );
}

export default NbaPlayerProfileCard;
