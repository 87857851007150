import { COLOR_BAD, COLOR_AVERAGE, COLOR_GOOD } from './score';

const padNumber = (value) => value.toString().padStart(2, '0');

const getTimeLeft = (endTimestamp) => {
  const end = new Date(endTimestamp);
  const now = Date.now();

  const timeLeftSeconds = Math.floor((end - now) / 1000);
  let timeTemp = timeLeftSeconds;

  const result = {
    days: 0, hours: 0, minutes: 0, seconds: 0,
  };
  result.seconds = timeTemp - Math.floor(timeTemp / 60) * 60;
  timeTemp = Math.floor(timeTemp / 60);
  result.minutes = timeTemp - Math.floor(timeTemp / 60) * 60;
  timeTemp = Math.floor(timeTemp / 60);
  result.hours = timeTemp - Math.floor(timeTemp / 24) * 24;
  timeTemp = Math.floor(timeTemp / 24);
  result.days = timeTemp;

  return result;
};

const getEndDate = (endTimestamp) => (new Date(endTimestamp)).toLocaleDateString();

const getTimeLeftFormatted = (endTimestamp, showEndDateTime) => {
  const resultTime = getTimeLeft(endTimestamp);

  if (resultTime.days < 0) {
    if (showEndDateTime) {
      return getEndDate(endTimestamp);
    }

    return 'EXPIRED';
  }

  if (resultTime.days > 0) {
    return `${resultTime.days}d ${resultTime.hours}h`;
  }

  return `${padNumber(resultTime.hours)}:${padNumber(resultTime.minutes)}:${padNumber(resultTime.seconds)}`;
};

const getTimeLeftColor = (endTimestamp) => {
  const resultTime = getTimeLeft(endTimestamp);

  if (resultTime.days < 0) {
    return COLOR_BAD;
  }

  if (resultTime.hours <= 0) {
    if (resultTime.minutes >= 2) {
      return resultTime.minutes <= 10 ? COLOR_AVERAGE : COLOR_GOOD;
    }

    return COLOR_BAD;
  }

  return COLOR_GOOD;
};

export {
  padNumber, getTimeLeft, getTimeLeftColor, getTimeLeftFormatted,
};
