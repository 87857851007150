import React, { useState, useEffect } from 'react';
import {
  Card, Spin, Space, DatePicker,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import Chart from '../components/Chart';

import withData from '../store/withData';
import { getRequest } from '../utils/api';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DataCard = ({ name, value }) => (
  <div className="sa-dashboard-card">
    <Card title={name}>{value}</Card>
  </div>
);

const ChartCard = ({
  name, data, xAxisName, yAxisName, yAxisRightName, yAxisColor, yAxisRightColor,
}) => {
  const [dates, setDates] = useState([null, null]);

  const onDatesChange = (updatedDates, dateStrings) => {
    const startDate = (new Date(dateStrings[0])).getTime();
    const endDate = (new Date(dateStrings[1])).getTime();
    setDates([startDate, endDate]);
  };

  const chartData = [];
  /* eslint-disable-next-line */
  for (const item of data) {
    const itemDate = (new Date(item.updated)).getTime();

    if (!dates[0] || !dates[1] || (itemDate >= dates[0] && itemDate <= dates[1])) {
      chartData.push(item);
    }
  }

  const title = (
    <Space>
      {name}
      <DatePicker.RangePicker onChange={onDatesChange} />
    </Space>
  );

  return (
    <div className="sa-dashboard-chart">
      <Card title={title}>
        <Chart
          data={chartData}
          width={1000}
          height={300}
          isLoading={false}
          xAxisName={xAxisName}
          yAxisName={yAxisName}
          yAxisRightName={yAxisRightName}
          yAxisColor={yAxisColor}
          yAxisRightColor={yAxisRightColor}
        />
      </Card>
    </div>
  );
};

const DashboardView = (props) => {
  const { dataContext } = props;

  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const data = await getRequest('stats/summary');
      dataContext.setStatsSummary(data || {});
      setLoading(false);
    }
    if (update) {
      setUpdate(false);
      loadData();
    }
  }, [update, dataContext]);

  const {
    lastSnapshot, snapshots,
  } = dataContext.statsSummary;

  const {
    players = 0, players24h = 0, cards = 0, cards24h = 0,
  } = lastSnapshot.data;

  document.title = 'SorareAce - Dashboard';

  const formattedDate = (new Date(lastSnapshot.updated)).toDateString();

  const chartData = [];
  /* eslint-disable-next-line */
  for (const item of snapshots) {
    chartData.push({
      updated: item.updated,
      date: (new Date(item.updated)).toLocaleDateString(),
      players: item.data.players,
      cards: item.data.cards,
      players24h: item.data.players24h,
      cards24h: item.data.cards24h,
      limited: item.data.limited24h,
      rare: item.data.rare24h,
      super: item.data.super24h,
      unique: item.data.unique24h,
    });
  }

  return (
    <Spin indicator={LoadingIcon} spinning={loading}>
      <div className="sa-dashboard-view">
        <div className="sa-dashboard-cards">
          <DataCard name="Last Update Date" value={formattedDate} />
          <DataCard name="Total Players" value={players} />
          <DataCard name="New Players in 24h" value={players24h} />
          <DataCard name="Total Cards" value={cards} />
          <DataCard name="New Cards in 24h" value={cards24h} />
        </div>
        <div className="sa-dashboard-charts">
          <ChartCard
            name="Number of Cards and Players"
            data={chartData}
            xAxisName="date"
            yAxisName="cards"
            yAxisRightName="players"
          />
        </div>
        <div className="sa-dashboard-charts">
          <ChartCard
            name="New Cards and Players"
            data={chartData}
            xAxisName="date"
            yAxisName="cards24h"
            yAxisRightName="players24h"
          />
        </div>
        <div className="sa-dashboard-charts">
          <ChartCard
            name="New Cards by rarity"
            data={chartData}
            xAxisName="date"
            yAxisName="limited"
            yAxisRightName="rare"
            yAxisColor="rgb(215, 190, 28)"
            yAxisRightColor="rgb(189, 44, 58)"
          />
        </div>
        <div className="sa-dashboard-charts">
          <ChartCard
            name="New Cards by rarity"
            data={chartData}
            xAxisName="date"
            yAxisName="super"
            yAxisRightName="unique"
            yAxisColor="rgb(31, 161, 255)"
            yAxisRightColor="rgb(95, 47, 3)"
          />
        </div>
      </div>
    </Spin>
  );
};

export default withData(DashboardView);
