import React, { useState, useEffect, useCallback } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import withData from '../store/withData';
import { getRequest } from '../utils/api';
import PlayersList from '../components/PlayersList';
import Filters from '../components/Filters';
import { ViewTypes, generateParameters } from '../utils/filter';

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DEFAULT_LIMIT = 100;

const TopPlayersView = (props) => {
  const { dataContext } = props;

  const [update, setUpdate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedRarity, setSelectedRarity] = useState('rare');
  const [selectedScoresType, setSelectedScoresType] = useState('total');
  const [selectedScoresSort, setSelectedScoresSort] = useState('last30');

  const prepareParams = useCallback((offset) => {
    let params = `?offset=${offset}&limit=${DEFAULT_LIMIT}`;

    const filterParams = generateParameters(ViewTypes.TOP_PLAYERS, dataContext);

    if (filterParams.length) {
      params += `&${filterParams.join('&')}`;
    }

    return params;
  }, [dataContext]);

  useEffect(() => {
    async function loadTopPlayersData() {
      setLoading(true);
      const params = prepareParams(0);
      const data = await getRequest(`players/top${params}`);

      setSelectedRarity(dataContext.rarityPricesSelectValue);
      setSelectedScoresType(dataContext.scoresTypeSelectValue);
      setSelectedScoresSort(dataContext.scoresSortSelectValue);

      dataContext.setTopPlayersData(data || { items: [] });
      setLoading(false);
    }
    if (update) {
      setUpdate(false);
      loadTopPlayersData();
    }
  }, [update, dataContext, prepareParams]);

  document.title = 'SorareAce - Top Players';

  return (
    <div className="sa-auction-view">
      <Spin indicator={LoadingIcon} spinning={loading}>
        <Filters dataContext={dataContext} setUpdate={setUpdate} view={ViewTypes.TOP_PLAYERS} type="market/listed" />
        <div className="sa-scores-table">
          <PlayersList
            data={dataContext.topPlayersData.items}
            selectedRarity={selectedRarity}
            selectedScoresType={selectedScoresType}
            selectedScoresSort={selectedScoresSort}
          />
        </div>
      </Spin>
    </div>
  );
};

export default withData(TopPlayersView);
