const COLOR_DNP = '#EFEFEF';
const COLOR_AWFUL = 'rgba(255, 0, 0, 0.95)';
const COLOR_BAD = 'rgba(255, 0, 0, 0.75)';
const COLOR_LESS_THAN_AVERAGE = '#FFA31A';
const COLOR_AVERAGE = '#F1CE17';
const COLOR_MORE_THAN_AVERAGE = '#B6CC2C';
const COLOR_GOOD = '#2CCC89';

const getColorForScore = (score) => {
  if (score === null) {
    return COLOR_DNP;
  }

  if (score === undefined) {
    return COLOR_AWFUL;
  }

  const roundedScore = Math.round(score);

  if (roundedScore <= 0) {
    return COLOR_AWFUL;
  }

  if (roundedScore < 20) {
    return COLOR_BAD;
  }

  if (roundedScore < 30) {
    return COLOR_LESS_THAN_AVERAGE;
  }

  if (roundedScore < 45) {
    return COLOR_AVERAGE;
  }

  if (roundedScore < 60) {
    return COLOR_MORE_THAN_AVERAGE;
  }

  return COLOR_GOOD;
};

const scoreMarks = {
  0: {
    style: {
      color: getColorForScore(0),
      fontWeight: 700,
    },
    label: '0',
  },
  20: {
    style: {
      color: getColorForScore(20),
      fontWeight: 700,
    },
    label: '20',
  },
  30: {
    style: {
      color: getColorForScore(30),
      fontWeight: 700,
    },
    label: '30',
  },
  40: {
    style: {
      color: getColorForScore(40),
      fontWeight: 700,
    },
    label: '40',
  },
  45: {
    style: {
      color: getColorForScore(45),
      fontWeight: 700,
    },
    label: '45',
  },
  50: {
    style: {
      color: getColorForScore(50),
      fontWeight: 700,
    },
    label: '50',
  },
  55: {
    style: {
      color: getColorForScore(55),
      fontWeight: 700,
    },
    label: '55',
  },
  60: {
    style: {
      color: getColorForScore(60),
      fontWeight: 700,
    },
    label: '60',
  },
  70: {
    style: {
      color: getColorForScore(70),
      fontWeight: 700,
    },
    label: '70',
  },
  80: {
    style: {
      color: getColorForScore(80),
      fontWeight: 700,
    },
    label: '80',
  },
  100: {
    style: {
      color: getColorForScore(100),
      fontWeight: 700,
    },
    label: '100',
  },
};

export {
  getColorForScore,
  scoreMarks,
  COLOR_DNP,
  COLOR_BAD,
  COLOR_LESS_THAN_AVERAGE,
  COLOR_AVERAGE,
  COLOR_MORE_THAN_AVERAGE,
  COLOR_GOOD,
};
