/* eslint-disable no-nested-ternary */
import React from 'react';
import { Table, Tooltip } from 'antd';

import { getColorForScore } from '../utils/score';

const getDecisiveElements = (data) => {
  let result = [];

  // Negative:

  const yellowCard = data.stats?.general?.yellowCard || 0;
  if (yellowCard > 0) {
    result = result.concat(Array(yellowCard).fill((
      <Tooltip title="Yellow card" color="red"><span>&#129000;</span></Tooltip>
    )));
  }

  const redCard = data.stats?.negativeDecisive?.redCard || 0;
  if (redCard > 0) {
    result = result.concat(Array(redCard).fill((
      <Tooltip title="Red card" color="red"><span>&#128997;</span></Tooltip>
    )));
  }

  const penaltyConceded = data.stats?.negativeDecisive?.penaltyConceded || 0;
  if (penaltyConceded > 0) {
    result = result.concat(Array(penaltyConceded).fill((
      <Tooltip title="Penalty conceded" color="red"><span className="sa-emoji-color-red">&#129349;</span></Tooltip>
    )));
  }

  const errorLeadToGoal = data.stats?.negativeDecisive?.errorLeadToGoal || 0;
  if (errorLeadToGoal > 0) {
    result = result.concat(Array(errorLeadToGoal).fill((
      <Tooltip title="Error lead to goal" color="red"><span className="sa-emoji-color-red">&#128095;</span></Tooltip>
    )));
  }

  const ownGoals = data.stats?.negativeDecisive?.ownGoals || 0;
  if (ownGoals > 0) {
    result = result.concat(Array(ownGoals).fill((
      <Tooltip title="Own goal" color="red"><span className="sa-emoji-color-red">&#9917;</span></Tooltip>
    )));
  }

  const threeGoalsConceded = data.stats?.negativeDecisive?.threeGoalsConceded || 0;
  if (threeGoalsConceded > 0) {
    result = result.concat(Array(threeGoalsConceded).fill((
      <Tooltip title="3 goals conceded" color="red"><span className="sa-emoji-color-red">&#127783;</span></Tooltip>
    )));
  }

  // Positive:

  const goals = data.stats?.positiveDecisive?.goals || 0;
  if (goals > 0) {
    result = result.concat(Array(goals).fill((
      <Tooltip title="Goal" color="green"><span>&#9917;</span></Tooltip>
    )));
  }

  const assists = data.stats?.positiveDecisive?.goalAssist || 0;
  if (assists > 0) {
    result = result.concat(Array(assists).fill((
      <Tooltip title="Assist" color="green"><span>&#128095;</span></Tooltip>
    )));
  }

  const penaltyWon = data.stats?.positiveDecisive?.penaltyWon || 0;
  if (penaltyWon > 0) {
    result = result.concat(Array(penaltyWon).fill((
      <Tooltip title="Penalty won" color="green"><span className="sa-emoji-color-green">&#129349;</span></Tooltip>
    )));
  }

  const clearanceOffLine = data.stats?.positiveDecisive?.clearanceOffLine || 0;
  if (clearanceOffLine > 0) {
    result = result.concat(Array(clearanceOffLine).fill((
      <Tooltip title="Clearance off the line" color="green"><span>&#129686;</span></Tooltip>
    )));
  }

  const lastManTackle = data.stats?.positiveDecisive?.lastManTackle || 0;
  if (lastManTackle > 0) {
    result = result.concat(Array(lastManTackle).fill((
      <Tooltip title="Last man tackle" color="green"><span>&#128735;</span></Tooltip>
    )));
  }

  const penaltySave = data.stats?.positiveDecisive?.penaltySave || 0;
  if (penaltySave > 0) {
    result = result.concat(Array(penaltySave).fill((
      <Tooltip title="Penalty saved" color="green"><span>&#129508;</span></Tooltip>
    )));
  }

  const cleanSheet = data.stats?.positiveDecisive?.cleanSheet || 0;
  if (cleanSheet > 0) {
    result = result.concat(Array(cleanSheet).fill((
      <Tooltip title="Clean sheet" color="green"><span>&#11088;</span></Tooltip>
    )));
  }

  return result;
};

const getMatchScoreRenderer = (homeTeam, data) => {
  const playerTeamGoals = data.playerTeam === homeTeam ? data.homeGoals : data.awayGoals;
  const nonPlayerTeamGoals = data.playerTeam === homeTeam ? data.awayGoals : data.homeGoals;

  let color = 'orange';
  if (playerTeamGoals > nonPlayerTeamGoals) {
    color = 'rgb(44, 204, 137)';
  } else if (playerTeamGoals < nonPlayerTeamGoals) {
    color = 'red';
  }
  const result = (
    <div className="sa-auction-time-badges-block">
      <span style={{ fontWeight: data.playerTeam === homeTeam ? 'bold' : 'normal', width: '40%', textAlign: 'right' }}>
        {homeTeam}
      </span>
      <span style={{ padding: '0px 10px 0px 10px', color, fontWeight: 'bold' }}>
        {`${data.homeGoals} - ${data.awayGoals}`}
      </span>
      <span style={{ fontWeight: data.playerTeam === data.awayTeam ? 'bold' : 'normal', width: '40%', textAlign: 'left' }}>
        {data.awayTeam}
      </span>
    </div>
  );

  return result;
};

const getPlayedStatusRenderer = (minsPlayed, data) => {
  const started = <Tooltip title="Started" color="green"><span className="sa-emoji-color-green">&#128085;</span></Tooltip>;
  const played = <Tooltip title="Substituted in" color="green"><span className="sa-emoji-color-green">&#128257;</span></Tooltip>;
  const bench = <Tooltip title="On the Bench" color="red"><span className="sa-emoji-color-red">&#128085;</span></Tooltip>;
  const notInTheSquad = <Tooltip title="Not in the Squad" color="red"><span>&#10060;</span></Tooltip>;
  const { hasStarted, hasBeenInTheSquad } = data.stats.stats;
  return (
    <>
      {hasStarted ? started : !hasBeenInTheSquad ? notInTheSquad : minsPlayed > 0 ? played : bench}
      {minsPlayed > 0 && `  (${minsPlayed})`}
    </>
  );
};

function ScoresList({ data }) {
  const columns = [
    {
      title: 'Game Week',
      dataIndex: 'gameWeek',
      key: 'gameWeek',
    },
    {
      title: 'Match Date',
      dataIndex: 'matchDate',
      key: 'matchDate',
      render: (matchDate) => (
        (new Date(matchDate)).toLocaleString()
      ),
    },
    {
      title: 'Game Status',
      dataIndex: 'gameStatus',
      key: 'gameStatus',
    },
    {
      title: 'Game',
      dataIndex: 'homeTeam',
      key: 'homeTeam',
      render: getMatchScoreRenderer,
    },
    {
      title: 'Game Type',
      dataIndex: 'isClubGame',
      key: 'isClubGame',
      render: (isClubGame) => (
        isClubGame ? 'Club' : 'National Team'
      ),
    },
    {
      title: 'Minutes Played',
      dataIndex: 'minutesPlayed',
      key: 'minutesPlayed',
      render: getPlayedStatusRenderer,
    },
    {
      title: 'Decisive Actions',
      dataIndex: 'stats',
      key: 'statsDecisiveActions',
      render: (statsData) => {
        const actions = getDecisiveElements(statsData);
        return <div className="sa-decisive-scores-icons">{actions}</div>;
      },
    },
    {
      title: 'Decisive Score',
      dataIndex: 'stats',
      key: 'statsDecisiveScore',
      render: (statsData) => statsData.newScore?.decisiveScore || 0,
    },
    {
      title: 'All Around Score',
      dataIndex: 'stats',
      key: 'statsAAScore',
      render: (statsData) => statsData.newScore?.allAroundScore || 0,
    },
    {
      title: 'Score Value',
      dataIndex: 'value',
      key: 'value',
      render: (value, item) => (
        <div style={{ color: getColorForScore(value), fontWeight: 'bold' }}>{item.minutesPlayed > 0 ? value : 'DNP'}</div>
      ),
    },
  ];

  return (
    <Table dataSource={data} columns={columns} pagination={false} />
  );
}

export default ScoresList;
